<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="control-label"> {{ $t("address.addressline") }} </label>
          <b-form-input
            list="input-list"
            id="input-with-list"
            :placeholder="$t('address.addressline')"
            v-model="address.line1"
            class="form-control"
            @keypress="getLiveAddress"
            aria-describedby="addon-right addon-left"
            @change="changed"
          ></b-form-input>
          <b-form-datalist
            id="input-list"
            :options="options"
            text-field="properties.label"
            value-field="properties.label"
            @click="changed"
          ></b-form-datalist>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 pr-md-1">
        <div class="form-group">
          <label class="control-label"> {{ $t("address.city") }} </label
          ><input
            aria-describedby="addon-right addon-left"
            :placeholder="$t('address.city')"
            class="form-control"
            v-model="address.city"
          />
        </div>
      </div>
      <div class="col-md-4 px-md-1">
        <div class="form-group">
          <label class="control-label"> {{ $t("address.country") }} </label
          ><input
            aria-describedby="addon-right addon-left"
            :placeholder="$t('address.country')"
            class="form-control"
            v-model="address.country"
          />
        </div>
      </div>
      <div class="col-md-4 pl-md-1">
        <div class="form-group">
          <label class="control-label"> {{ $t("address.zipCode") }} </label
          ><input
            aria-describedby="addon-right addon-left"
            :placeholder="$t('address.zipCode')"
            class="form-control"
            v-model="address.zipCode"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "paydone-address",
  props: {
    language: {
      type: String,
      default: "fr",
    },
    address: {
      line1: "",
      line2: "",
      line3: "",
      city: "",
      country: "",
      zipCode: "",
    },
    liveCheck: true,
    reqHeader: {
      method: "GET",
      headers: new Headers(),
      mode: "cors",
      cache: "default",
    },
  },
  components: {},
  data() {
    return {
      endpoint: "https://api-adresse.data.gouv.fr/search/",
      options: [],
    };
  },
  methods: {
    getLiveAddress() {
      //console.log(this.address.addressline.length > 10);
      if (this.address.addressline && this.address.addressline.length > 10) {
        fetch(this.endpoint + this.getQuery(), this.reqHeader)
          .then((response) => {
            return response.json();
          })
          .then((resp) => {
            //alimenter une liste déroulate pour faire le choix.
            this.options = resp.features;
          });
      }
    },
    getQuery() {
      return "?q=" + this.address.addressline.replace(" ", "+");
    },
    changed(event){
        const newAdd = this.options.filter(o => o.properties.label == event)
        this.address.city = newAdd.city;
        this.address.zipCode = newAdd.postcode;
    }
  },
};
</script>
