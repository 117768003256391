<template>
  <card :title="loading ? $t('common.loading') : $t('transaction.title')">
    <transaction-detail
      :transaction="currTrans"
      :showTransactionDetails="showTransactionDetails"
      @showTransactionDetails="showTransactionDetailChanges"
      @saveTransaction="saveTransaction"
    >
    </transaction-detail>
    <b-table
      striped
      hover
      :items="transactions"
      :fields="bheader"
      id="tableTransactions"
      :per-page="perPage"
      :current-page="currentPage"
      :busy="loading"
    >
      <template #cell(action)="row">
        <base-button
          type="link"
          aria-label="edit button"
          style="color: #00f2c3"
          @click="transactionDetail(row.item)"
        >
          <i class="tim-icons icon-pencil"></i>
        </base-button>
        <b-button variant="link">
            <b-icon
              icon="box-arrow-in-up-right"
              aria-hidden="true"
              style="color: #00f2c3"
              @click="openTransactionDetail(row.item)"
            >
            </b-icon>
          </b-button>
      </template>
      <template #cell(createdAt)="row">
        <p class="title">
          {{ row.item.createdAt | formatDate }}
        </p>
      </template>
      <template #cell(status)="row">
        <div v-if="row.item.payment.status == 'succeeded'">
          <b-img-lazy src="img/paydone_check.png" width="20px" height="20px">
          </b-img-lazy>
          <div class="hidden-sm">
            {{ $t("payment.status." + row.item.payment.status) }}
          </div>
        </div>
        <div  v-else-if="row.item.payment.status == 'requires_capture'" >
          <div class="h2 mb-0">
            <b-icon icon="stopwatch" variant="warning"></b-icon>
          </div>
          {{ $t("payment.status." + row.item.payment.status) }}
        </div>
        <div v-else >
          <div class="h2 mb-0">
            <b-icon icon="exclamation-circle" variant="danger"></b-icon>
          </div>
          {{ $t("payment.status." + row.item.payment.status) }}
         
        </div>
      </template>
      <template #cell(amount)="row">
        {{ row.item.amount / 100 }}
        {{ row.item.currency ? row.item.currency : "€" }}
      </template>
      <template #cell(captured)="row">
        <p class="title">
          {{ row.item.payment.amount_capturable / 100 }}
          {{ row.item.currency ? row.item.currency : "€" }}
        </p>
        <p class="text-muted" style="color: #02999d !important">
          {{ row.item.payment.amount_received / 100 }}
          {{ row.item.currency ? row.item.currency : "€" }}
        </p>
      </template>
      <template #table-busy>
          <div class="row">
            <div
              class="col-md-12 pr-md-1"
              align="center"
              style="margin-bottom: 5px"
            >
              <loading :isLoading="loading"></loading>
            </div>
          </div>
        </template>
    </b-table>
    <div class="row">
      <div class="col-md-12 pr-md-1">
        <b-pagination
          v-model="currentPage"
          size="sm"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="tableTransactions"
          align="center"
          :current-page="currentPage"
        ></b-pagination>
      </div>
    </div>
  </card>
</template>

<script>
import { BaseTable } from "@/components";
import Modal from "@/components/Modal";
import TransactionDetail from "./transactionDetail";
import loading from "@/components/loading";

export default {
  components: {
    BaseTable,
    Modal,
    TransactionDetail,
    loading,
  },
  data() {
    return {
      transactions: [],
      header: ["Date", "Amount", "Capturable / Received", "Status", "Action"],
      bheader: [
        {
          key: "createdAt",
          label: this.$t("common.createdAt"),
          sortable: true,
          class: "title",
        },
        {
          key: "Amount",
          label: this.$t("transaction.amount"),
          sortable: false,
          _rowVariant: "title",
        },
        {
          key: "captured",
          label:
            this.$t("transaction.amount_capturable") +
            "/ " +
            this.$t("transaction.amount_received"),
        },
        {
          key: "status",
          label: this.$t("common.status"),
          sortable: false,
        },
        {
          key: "action",
          sortable: false,
        },
      ],
      showTransactionDetails: false,
      currTrans: {},
      perPage:
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).options
          ? JSON.parse(localStorage.getItem("user")).options.numberLine
          : "6",
      currentPage: 1,
    };
  },
  methods: {
    gettransactions() {
      //here get commande for the connected user.
      this.transactions = [];
    },
    transactionDetail(transaction) {
      this.currTrans = transaction;
      this.showTransactionDetails = true;
    },

    showTransactionDetailChanges(check) {
      this.showTransactionDetails = check;
    },
    saveTransaction(o) {
      this.$notify({
        message: $t("transaction.saved"),
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "success",
      });
    },
    openTransactionDetail(transaction) {
      window.open(`/#/transactions/${transaction._id}`, "_blank");
    }
  },
  created() {
    this.loading = true;
    this.$store.dispatch("transactions/list", this.transactions).then(
      (t) => {
        this.loading = false;
        this.transactions = t;
      },
      (error) => {
        this.loading = false;
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.$notify({
          message: this.message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
        });
        localStorage.removeItem("user");
        this.$router.push("/login");
      }
    );
  },
  computed: {
    rows() {
      return this.transactions.length;
    },
  },
};
</script>

<style >
</style>