<template>
  <div>
    <b-row class="pb-2">
        <b-col cols="10">
          <h1 slot="header" class="text-uppercase text-center">
            <p class="title text-white">
              {{
                $t("order.label") +
                " " +
                (order.externalId ? order.externalId : order._id)
              }}
            </p>
            <p class="text-muted">{{ order.createdAt | formatDate }}</p>
            
          </h1>
        </b-col>
        <b-col cols="2">
          <div v-if="order.validated">
            <b-img-lazy src="img/paydone_check.png"> </b-img-lazy>
          </div>
          <b-icon
            v-else
            stacked
            icon="stopwatch"
            variant="warning"
          ></b-icon>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h2 class="text-center">
            <span>
              {{ order.customers[0].firstname }}
            </span>
            <span class="text-uppercase">
              {{ order.customers[0].lastname }}
            </span>
          </h2>
        </b-col>
      </b-row>
    
    <b-row>
        <b-col cols="12">
          <b-card-group deck>
            <b-card
              bg-variant="info"
              text-variant="white"
              :header="$t('order.capturableAmount')"
              class="text-center"
            >
              <b-card-text>
                <h2 class="text-uppercase text-center mb-2">
                    {{
                      order.toBeCapturedAmount ? order.toBeCapturedAmount / 100 : 0
                    }}
                  </h2>
              </b-card-text>
            </b-card>

            <b-card
              bg-variant="primary"
              text-variant="white"
              :header="$t('order.recievedAmount')"
              class="text-center"
            >
              <b-card-text>
                <h2 class="text-uppercase text-center mb-2">
                  {{
                    order.capturedAmount ? order.capturedAmount / 100 : 0
                  }}
                </h2>
              </b-card-text>
            </b-card>

            <b-card
              bg-variant="danger"
              text-variant="white"
              :header="$t('order.lossAmount')"
              class="text-center"
            >
              <b-card-text>
                <h2 class="text-uppercase text-center mb-2">
                  {{ (order.totalAmount - order.capturedAmount) / 100 }}€
                </h2>
              </b-card-text>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
  </div>
</template>
<script>

export default {
  name: "order-header",
  components: {
  },

  props: {
    order: {
      type: Object,
      description: "Current order",
    }
  },
  data() {
    return {
      currencySymb: { eur: "€", dol: "$", $: "$", "€": "€" },
      addressCollapsed: true,
    };
  },
  methods: {
    addressBlocChanged() {
      this.addressCollapsed = !this.addressCollapsed;
    },
  },
  mounted() {
    //load order details if needed
  },
  created() {
  },
  computed: {
    showMee: {
      get() {
        return this.showOrderDetails;
      },
      set(check) {
        this.$emit("showOrderDetails", check);
      },
    },
    displayTotalAmount() {
      return (
        this.order.totalAmount / 100 +
        "" +
        this.currencySymb[this.order.currency || 'eur']
      );
    },
  },
};
</script>

<style scoped>
.form-control {
  color: white;
}
</style>

