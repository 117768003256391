<template>
  <card :title="loading ? $t('common.loading') : $t('invoice.label')">
    <invoice-detail :invoice="currInvoice"  :showInvoiceDetails="showInvoiceDetails"
      @showInvoiceDetails="showInvoiceDetailChanges"
      @saveInvoice="saveInvoice">

    </invoice-detail>
    <div class="row">
        <div class="col-md-12 pr-md-1" align="center" style="margin-bottom:5px;">
            <loading :isLoading="this.loading"></loading>
        </div>
      </div>
      <b-table 
      hover
      striped
      :items="invoices"
      :fields="bheader"
      id="tableInvoices"
      :per-page="perPage"
      :current-page="currentPage"
      v-if="!loading">
      <template #cell(action)="row">
        <base-button
          type="link"
          aria-label="edit button"
          style="color: #00f2c3"
          @click="invoiceDetail(row.item)"
        >
          <i class="tim-icons icon-pencil"></i>
        </base-button>
      </template>
      <template #cell(createdAt)="row">
        <p class="title">
          {{ row.item.createdAt | formatDate }}
        </p>
      </template>
      <template #cell(invoiceNumber)="row">
        <p class="title">
          {{ row.item.invoiceNumber }}
        </p>
      </template>
      <template #cell(status)="row">
          <!-- {{ row.item.payment.status }} -->
          <div v-if="row.item.transaction.payment.status=='succeeded'">
          <b-img-lazy  src="img/paydone_check.png" width="20px" height="20px">            
          </b-img-lazy>    
                   
          </div>
          <p v-else >
            {{ row.item.payment.transaction.status }}
            <i class="tim-icons icon-alert-circle-exc" style="color: red"></i>
          </p>
      </template>
      <template #cell(amount)="row">
          {{ row.item.transaction.amount / 100}} {{ (row.item.transaction.payment.currency?row.item.transaction.payment.currency : '€') }}
      </template>

      </b-table>
      <div class="row" >
      <div class="col-md-12 pr-md-1">
        <b-pagination
          v-model="currentPage"
          size="sm"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="tableInvoices"
          align="center"
          :current-page="currentPage"
        ></b-pagination>
      </div>
    </div>
  </card>
</template>

<script>
import { BaseTable } from "@/components";
import Modal from "@/components/Modal";
import InvoiceDetail from '@/pages/invoice/invoiceDetail'
import loading from "@/components/loading";

export default {
  components: {
    BaseTable,
    Modal,
    InvoiceDetail,
    loading
  },
  data() {
    return {
      invoices: [],
      bheader: [
        {
          key: "createdAt",
          label: this.$t('common.createdAt'),
          sortable: true,
          class: "title",
        },
        {
          key: "transaction.customerId",
          label: this.$t('customer.email'),
          sortable: true,
          class: "title",
        },
        {
          key: "transaction.orderId",
          label: this.$t('order.label'),
          sortable: true,
          class: "title",
        },
        {
          key: "Amount",
          label: this.$t('common.amount'),
          sortable: false,
          _rowVariant: "title",
        },
        {
          key: "status",
          label : this.$t('common.status'),
          sortable: false,
        },
        {
          key: "action",
          sortable: false,
        },
      ],
      showInvoiceDetails: false,
      currInvoice: {},
      perPage : JSON.parse(localStorage.getItem("user")) && JSON.parse(localStorage.getItem("user")).options ? JSON.parse(localStorage.getItem("user")).options.numberLine :"6",
      currentPage : 1,
    };
  },
  methods: {
    getInvoices() {
      //here get commande for the connected user.
      this.invoices = [];
    },
    invoiceDetail(invoice) {
      this.currInvoice = invoice;
      this.showInvoiceDetails = true;
    },

    showInvoiceDetailChanges(check) {
      console.log("changing Invoice details")
      this.showInvoiceDetails = check;
    },
    saveInvoice(o) {
      this.$notify({
        message:  $t('invoice.saved'),
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "success",
      });
    },
  },
  created() {
    this.loading = true;
    this.$store.dispatch("invoices/list", this.invoices).then(
      (t) => {
        this.loading = false;
        console.log("invoices",t)
        this.invoices = t;
      },
      (error) => {
        this.loading = false;
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.$notify({
          message: this.message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
        });
        localStorage.removeItem("user");
        this.$router.push("/login");
      }
    );
  },
  computed: {
    rows() {
      return this.invoices.length;
    },
  }
};
</script>

<style >
</style>