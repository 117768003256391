<template>
    <div v-if="isLoading">
            <div class="lds-dual-ring"></div>
    </div> 
</template>
<script>


export default {
    name:'loading',
    props : {
        isLoading : false,
        spinnerColor : '#0e979c',
    },
}
</script>
<style scoped>

.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 0px;
  border-radius: 50%;
  border: 6px solid #0e979c;
  border-color: #0e979c transparent #0e979c transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
