import ejs from 'ejs'

const html = `<div id='printJS-order'>
    <div class="center">
        <div class="header">
            <table style="width: 100%;">
                <tr>
                    <td><img src='img/mve.jpg' width="50px" height="50px" /></td>
                    <td>
                        <h1 style="color:#4e80c2;">BON DE LIVRAISON</h1>
                    </td>
                </tr>
            </table>
        </div>
        <h2 style="uppercase">Commande N <%= (order.externalId ? order.externalId : order._id)  %> </h2>
        <div class="content">
            <div class="content-header">
                <table style="width: 80%;">
                    <tr>
                        <td>
                            <div class="header-address">
                                <p>Adresse de livraison :</p>
                                <p>
                                    <%= order.shipping.address.line1 %>
                                    <%= order.shipping.address.line2
                                        ? order.shipping.address.line2
                                        : "" %>
                                </p>

                                <p>
                                <%= order.shipping.address.zipCode %>
                                <%= order.shipping.address.city %>
                                </p>
                            </div>
                        </td>
                        <td>
                            <div class="contact-address">
                                <p>Contact</p>
                                <p>
                                    <%= order.customers[0].firstname %>
                                    <%= order.customers[0].lastname %>
                                </p>
                                <p>
                                    Tel : <%= order.customers[0].phone %>
                                    , email : <%= order.customers[0].email %>
                                </p>
                            </div>
                        </td>
                    </tr>
                </table>
                <% order.customers.forEach(function(cust){ if(cust.amount>0) {%>
                <div class="header-order">
                    <br /> <br />
                    <h2>EPECURIEN :  <%= cust.firstname %> <%= cust.lastname %> </h2>
                </div>
                <div class="content-details">
                    <table class="table">
                        <thead class="thead">
                            <tr>
                                <th class='th'>Produit</th>
                                <th class='th'>Quantité</th>
                            </tr>
                        </thead>
                        <tbody>
                            <% cust.transactions.forEach(function(tx){ %>
                                <% if (tx.payment && tx.payment.status == 'succeeded') { %>
                                    <% tx.products.forEach(function(prod){ if(prod.qty > 0) { %>
                                        <tr>
                                            <td class='td'><%= prod.id %></td>
                                            <td class='td'><%=  prod.qty %></td>
                                        </tr>
                                    <% }}); %>
                                <% }; %>
                            <% }); %>
                        </tbody>
                    </table>
                </div>
                <% }}); %>
                <div class="content-footer"> </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <img src='img/paydone.png' width="120px" height="30px" />
    </div>
</div>`;

const getHtml = function(order){
    return ejs.render(html, {order})
}

export default {
  getHtml
};