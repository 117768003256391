<template>
    <card>
     <order-header :order="order"></order-header>
      <div class="row mt-2">
        <div class="col-md-12 mb-2">
          <b-list-group>
            <b-list-group-item >
              <b-button
                v-b-toggle="'collapse-address'"
                :pill="true"
                size="sm"
                class="mr-3 btn-icon btn-simple btn-round"
                @click="addressBlocChanged" 
              >
                <b-icon
                  icon="chevron-right"
                  :v-b-toggle="'collapse-address'"
                  :rotate="addressCollapsed ? 90 : 270"
                ></b-icon>
              </b-button>
              <span class="mr-auto" :v-b-toggle="'collapse-address'"
                >{{ $t('order.shippingAddress') }}</span
              >
            </b-list-group-item>
            <b-collapse
              :id="'collapse-address'"
              v-if="order.shipping && order.shipping.address"
            >
              <b-list-group-item class="">
                <b-card
                  bg-variant="light"
                  class="text-center mb-1"
                  align="center"
                  style="background: #02999d !important"
                >
                  <b-card-text class="">
                    <p>
                      {{ order.shipping.address.line1 }}
                    </p>
                    <p>
                      {{
                        order.shipping.address.zipCode +
                        " " +
                        order.shipping.address.city +
                        " - " +
                        order.shipping.address.country
                      }}
                    </p>
                  </b-card-text>
                </b-card>
              </b-list-group-item>
            </b-collapse>
          </b-list-group>
        </div>

        <!-- addresse de livraison -->
        <customer-order :customers="order.customers" :readOnly="order.validated"> </customer-order>
      </div>
      <div class="row">
        <div class="col-md-4 px-4" v-if="!order.validated">
          <base-button type="primary" block @click="saveOrder" :readOnly="order.validated"
            >{{$t('common.save')}}</base-button
          >
        </div>
        <!-- <div class="col-md-4">
          <base-button type="secondary" block @click="close"
            >{{$t('common.close')}}</base-button
          >
        </div> -->
        <div class="col-md-4 px-4" v-if="!order.validated">
          <base-button type="warning" block @click="ValidateOrder()"
            >{{$t('order.terminate')}}</base-button
          >
        </div>
      </div>
    </card>
</template>

<script>
import Modal from "@/components/Modal";
import CustomerOrder from "./CustomerOrder.vue";
import OrderHeader from "./OrderHeader.vue";
import { jsPDF } from "jspdf";

export default {
  
  name: "order-page",
  components: {
    Modal,
    CustomerOrder,
    OrderHeader,
  },

  props: {
    
    showOrderDetails: {
      type: Boolean,
      description: "show me",
      default : true,
    },
  },
  data() {
    return {
      currencySymb: { eur: "€", dol: "$", $: "$", "€": "€" },
      addressCollapsed : true,
      orderId : this.$route.params.id,
      order : {}
    };
  },
  methods: {
    close() {
      console.log("closed")
    },
    addressBlocChanged(){
      this.addressCollapsed = !this.addressCollapsed
      console.log("this.addressCollapsed",this.addressCollapsed)
    },
    bonLivraison() {
      console.log("print bon de livraison pour", this.order._id);
      console.log("this.order", this.order.toString());
      this.getPDF();
    },
    ValidateOrder(){
      //the order has been validated. first save new update
      
      this.order.validated = true;
      this.saveOrder()
      //capture amounts
        console.log("let's get some money")
      this.captureAlltransaction((err,capturedObj)=>{
        if(err)  return console.log(err)
        console.log(capturedObj)
        //this.getPDF()
      })
      
    },
    captureAlltransaction(cb){
      this.loading = true;

      this.$store.dispatch("orders/capture", this.order).then(
        (capturedObj) => {
          this.loading = false;
          this.close();
          //this.$emit("OrderCaptured", capturedObj)
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            message: this.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
        }
      );
    },
    getHtmlPrintable() {
      return `
      <div style="width : 500px; margin :auto; color: black;">
      <p> voici le premier essai de génération de BDC</p>
          <p>${this.order.totalAmount}</p>

      </div>
      `
    },
    getPDF() {
      var doc = new jsPDF();          
      var elementHandler = {
        '#ignorePDF': function (element, renderer) {
          return true;
        }
      };
      var source = this.getHtmlPrintable();
      doc.fromHTML(
          source,
          15,
          15,
          {
            'width': 180,'elementHandlers': elementHandler
          });

      doc.output("dataurlnewwindow");

      // doc.html(this.getHtmlPrintable(), {
      //   callback: function (doc) {
      //     doc.save();
      //   },
      //   x: 10,
      //   y: 10,
      // });
    },
    saveOrder() {
      //emit saving event for this specifique order
      this.loading = true;

      this.$store.dispatch("orders/save", this.order).then(
        (o) => {
          this.loading = false;
          this.order = o;
          this.close();
          // this.$router.push("/dashboard");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            message: this.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
          //localStorage.removeItem("user");
          //this.$router.push("/dashboard");
        }
      );
      this.$notify({
        message: $t('order.saved'),
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "success",
      });
      //this.$emit("saveOrder", this.order);
    },
  },
  mounted() {
    //load order details if needed
    this.loading = true
    this.$store.dispatch(`orders/get`, this.$route.params.id, this.order).then(
      (o) => {
        this.loading = false;
        this.order = o;
        // this.$router.push("/dashboard");
      },
      (error) => {
        
        this.loading = false;
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          console.log("error dispatch order/list", error)
        this.$notify({
          message: this.message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
        });
        localStorage.removeItem("user");
        this.$router.push("/login");
      }
    );
  },
  computed: {
    displayTotalAmount() {
      return (
        this.order.totalAmount / 100 +
        "" +
        this.currencySymb[this.order.currency || 'eur']
      );
    },
  },
};
</script>

<style scoped>
.form-control {
  color: white;
}
</style>
