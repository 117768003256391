<template>
  <modal
    :show.sync="showMee"
    id="transactionModal"
    :centered="false"
    :show-close="true"
    gradient="primary"
    footerClasses="modal-footer"
  >
    <card>
      <b-row class="pb-2">
        <b-col cols="10">
          <h1 slot="header" class="text-uppercase text-center">
            <p class="title text-white">
              {{ this.getHeader() }}
            </p>
            <p class="text-muted">{{ transaction.createdAt | formatDate }}</p>
          </h1>
        </b-col>
        <b-col cols="2">
          <div
            v-if="
              transaction.payment && transaction.payment.status == 'succeeded'
            "
          >
            <b-img-lazy src="img/paydone_check.png"> </b-img-lazy>
          </div>
          <b-icon
            v-else-if="
              transaction.payment &&
              transaction.payment.status == 'requires_capture'
            "
            stacked
            icon="stopwatch"
            variant="warning"
          ></b-icon>
          <p v-else class="text-white text-uppercase">
            <b-icon stacked icon="exclamation-circle" variant="danger"></b-icon>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card-group deck>
            <b-card
              bg-variant="warning"
              text-variant="white"
              :header="$t('transaction.amount')"
              class="text-center"
            >
              <b-card-text>
                <h2 class="text-uppercase text-center text-wrap my-2">
                  {{
                    transaction.payment
                      ? transaction.payment.amount / 100 +
                          transaction.payment.currency || "€"
                      : "Error!!"
                  }}
                </h2>
              </b-card-text>
            </b-card>

            <b-card
              bg-variant="secondary"
              text-variant="white"
              :header="$t('transaction.amount_capturable')"
              class="text-center"
            >
              <b-card-text>
                <h2 class="text-uppercase text-center text-wrap my-2">
                  {{
                    transaction.payment
                      ? transaction.payment.amount_capturable / 100 +
                          transaction.payment.currency || "€"
                      : "Error!!"
                  }}
                </h2>
              </b-card-text>
            </b-card>

            <b-card
              bg-variant="primary"
              text-variant="white"
              :header="$t('transaction.amount_received')"
              class="text-center"
            >
              <b-card-text>
                <h2 class="text-uppercase text-center text-wrap my-2" >
                  {{
                    transaction.payment
                      ? transaction.payment.amount_received / 100 +
                          transaction.payment.currency || "€"
                      : "Error!!"
                  }}
                </h2>
              </b-card-text>
            </b-card>
             <b-card
              bg-variant="danger"
              text-variant="white"
              :header="$t('transaction.refund')"
              class="text-center"
            >
              <b-card-text>
                <h2 class="text-uppercase text-center text-wrap my-2" >
                  {{
                    transaction.payment
                      ? transaction.payment.charges.data[0].refunds.total_count / 100 +
                          transaction.payment.currency || "€"
                      : "Error!!"
                  }}
                </h2>
              </b-card-text>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
      <!-- card info -->
      <b-row>
        <b-col class="mt-4">
          <paydone-card2  :valueFields="this.valueFields" :setType="cardType" :threedsecure="threedsecure">

          </paydone-card2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="py-4">
          <b-card>
            <!-- product  -->
            <transaction-products :transaction="transaction" :readOnly="true">
            </transaction-products>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        v-if="
          transaction.payment &&
          transaction.payment.charges &&
          transaction.payment.charges.data &&
          this.transaction.payment.charges.data[0]
        "
      >
        <b-col>
          <b-button
            @click="
              getCreditCardInvoice(
                transaction.payment.charges.data[0].receipt_url
              )
            "
          >
            {{ $t('transaction.cardReceipt')}}
            <b-icon
              icon="credit-card"
              aria-hidden="true"
              style="color: #00f2c3"
              @click="
                getCreditCardInvoice(
                  transaction.payment.charges.data[0].receipt_url
                )
              "
            >
            </b-icon>
          </b-button> </b-col
        ><b-col>
          <b-button @click="openOrderDetail">
            {{$t('order.label')}}
            <b-icon
              icon="box-arrow-in-up-right"
              aria-hidden="true"
              style="color: #00f2c3"
              @click="openOrderDetail"
            >
            </b-icon>
          </b-button>
        </b-col>
        <!-- <b-col>
          <b-button @click="getCreditCardInvoice(charge.receipt_url)">
            Duplicata
            <i class="tim-icons icon-send" style="color: #00f2c3"></i>
          </b-button>
        </b-col> -->
        <b-col>
          <b-button type="secondary" block @click="close"
            >{{ $t("common.close") }}
            <b-icon icon="x" variant="danger"></b-icon>
          </b-button>
        </b-col>
      </b-row>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import { BaseTable } from "@/components";
import transactionProducts from "./transactionProducts.vue";
import paydoneCard from '@/components/paydone/paydoneCard.vue'
import paydoneCard2 from '@/components/paydone/paydoneCard2.vue'

export default {
  name: "transaction-detail",
  components: {
    Modal,
    BaseTable,
    transactionProducts,
    paydoneCard,
    paydoneCard2,
  },

  props: {
    transaction: {
      type: Object,
      description: "Current Transaction",
    },
    showTransactionDetails: {
      type: Boolean,
      description: "show me",
    },
  },
  data() {
    return {
      currencySymb: { eur: "€", dol: "$", $: "$", "€": "€" },
      chargeHeader: [
        this.$t("transaction.amount"),
        this.$t("transaction.amount_received"),
        this.$t("transaction.reciept"),
      ],
    };
  },
  methods: {
    close() {
      this.$emit("showTransactionDetails", false);
    },
    saveTransaction() {
      //emit saving event for this specifique order
      this.loading = true;
      this.$store.dispatch("transactions/save", this.transaction).then(
        (p) => {
          this.loading = false;
          this.transaction = p;
          this.close();
          // this.$router.push("/dashboard");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            message: this.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
          localStorage.removeItem("user");
          this.$router.push("/login");
        }
      );
      this.$emit("savetransaction", this.order);
    },
    getInvoice(invoiceId) {
      //get invoice Object
      
      //print
      return "/invoices/" + invoiceId + "/";
    },
    getCreditCardInvoice(url) {
      window.open(url);
    },
    openOrderDetail() {
      window.open(`/#/orders/${this.transaction.orderId}`, "_blank");
    },
    getHeader() {
      return this.transaction.payment &&
        this.transaction.payment.charges.data[0]
        ? this.transaction.payment.charges.data[0].billing_details.name
        : "Error!!";
    },
    picStatus() {
      if (this.transaction.payment) {
        return "img/paydone_check.png";
      } else {
      }
    },
  },
  mounted() {
    //load order details if needed
  },
  computed: {
    showMee: {
      get() {
        return this.showTransactionDetails;
      },
      set(check) {
        this.$emit("showTransactionDetails", check);
      },
    },
    valueFields : {
      get(){
        const chargeData = (this.transaction && this.transaction.payment && this.transaction.payment.charges ?  this.transaction.payment.charges.data[0] : '')
        if (chargeData) {
          return  {
                    cardName: chargeData.billing_details.name,
                    cardNumber: "#### #### #### " + chargeData.payment_method_details.card.last4,
                    cardMonth: chargeData.payment_method_details.card.exp_month,
                    cardYear: chargeData.payment_method_details.card.exp_year,
                    cardCvv: chargeData.payment_method_details.card.checks.cvc_check,
                  }
        }else{
          return {
            cardName: '',
                    cardNumber: "#### #### #### ####" ,
                    cardMonth: '',
                    cardYear: '',
                    cardCvv: '',
          }
        }
      }
    },
    cardType: {
      get(){
        const chargeData = (this.transaction && this.transaction.payment && this.transaction.payment.charges ?  this.transaction.payment.charges.data[0] : '')
        if(chargeData) return chargeData.payment_method_details.card.brand
        return 'visa'
      }
    },
    threedssecure: {
      get(){
        const chargeData = (this.transaction && this.transaction.payment && this.transaction.payment.charges ?  this.transaction.payment.charges.data[0] : '')
        if(chargeData) return chargeData.payment_method_details.card.three_d_secure
        return null
      }
    }
  },
};
</script>
