<template>
  <card :title="loading ? this.$t('common.loading') : this.$t('customer.title')">
   <customer-detail :customer="currCust"  :showCustomerDetails="showCustomerDetails"
      @showCustomerDetails="showCustomerDetailChanges"
      @saveCustomer="saveCustomer">

    </customer-detail>
    <div class="row">
        <div class="col-md-12 pr-md-1" align="center" style="margin-bottom:5px;">
            <loading :isLoading="loading"></loading>
        </div>
      </div>
      <b-table striped
      hover
      :items="customers"
      :fields="bheader"
      id="tableCustomers"
      :per-page="perPage"
      :current-page="currentPage"
      v-if="!loading">
      <template #cell(action)="row">
        <base-button
          type="link"
          aria-label="edit button"
          style="color: #00f2c3"
          @click="customerDetail(row.item)"
        >
          <i class="tim-icons icon-pencil"></i>
        </base-button>
      </template>
      <template #cell(createdAt)="row">
        <p class="title">
          {{ row.item.createdAt | formatDate }}
        </p>
      </template>
      <template #cell(fullname)="row">
          <p class="title">{{ row.item.firstname + " " + row.item.lastname }}</p>
          <p class="text-muted">{{ row.item.phone }}</p>
      </template>
      <template #cell(email)="row">
          <p class="title">
            {{ row.item.email }}</p>
      </template>

      </b-table>
      <div class="row" >
      <div class="col-md-12 pr-md-1">
        <b-pagination
          v-model="currentPage"
          size="sm"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="tableTransactions"
          align="center"
          :current-page="currentPage"
        ></b-pagination>
      </div>
    </div>
    
  </card>
</template>

<script>
import { BaseTable } from "@/components";
import NotificationTemplate from "../Notifications/NotificationTemplate";
import Modal from "@/components/Modal";
import CustomerDetail from './customerDetail'

import loading  from "@/components/loading";

export default {
  components: {
    BaseTable,
    Modal,
    CustomerDetail,
    loading,
  },
  data() {
    return {
      customers: [],
      bheader: [
        {
          key: "createdAt",
          label: this.$t('common.createdAt'),
          sortable: true,
          class: "title",
        },
        {
          key: "fullName",
          label: this.$t('customer.fullname'),
          sortable: false,
          _rowVariant: "title",
        },
        {
          key: "email",
          label: this.$t('customer.email'),
        },
        {
          key: "action",
          label: this.$t('common.action'),
          sortable: false,
        },
      ],
      showCustomerDetails: false,
      currCust: {},
      perPage : JSON.parse(localStorage.getItem("user")) && JSON.parse(localStorage.getItem("user")).options ? JSON.parse(localStorage.getItem("user")).options.numberLine :"6",
      currentPage : 1,
      loading : true,
    };
  },
  methods: {
    getCustomers() {
      //here get commande for the connected user.
      this.customers = [];
    },
    customerDetail(customer) {
      this.currCust = customer;
      this.showCustomerDetails = true;
    },
    showCustomerDetailChanges(check) {
      console.log("changing customer details")
      this.showCustomerDetails = check;
    },
    saveCustomer(o) {
      this.$notify({
        message: "saved",
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "success",
      });
    },
  },
  created() {
    this.loading = true
    this.$store.dispatch("customers/list", this.customers).then(
      (c) => {
        this.loading = false;
        this.customers = c;
      },
      (error) => {
        this.loading = false;
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.$notify({
          message: this.message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
        });
        localStorage.removeItem("user");
        this.$router.push("/login");
      }
    );
  },
  computed: {
    rows() {
      return this.customers.length;
    },
  },
};
</script>

<style >
</style>