<template>

    <card>
      <b-row>
        <b-col cols="10">
          <h1 slot="header" class="text-uppercase text-center">
        <p class="title text-white">
        {{ this.getHeader() }}
       </p>
         <p class="text-muted">{{ transaction.createdAt | formatDate}}</p>
          <p class="text-white">{{ transaction.amount /100 + " "+ (transaction.currency ||'€') }}</p>
      </h1>
        </b-col>
        <b-col cols="2">
           <div v-if="transaction.payment.status == 'succeeded'">
          <b-img-lazy src="img/paydone_check.png" >
          </b-img-lazy>
          <!-- <div class="hidden-sm">
            {{ $t("payment.status." + transaction.payment.status) }}
          </div> -->
        </div>

        <!-- <i
          style="color: orange"
          v-else-if="transaction.payment.status == 'requires_capture'"
          class="tim-icons icon-coins text-uppercase"
        >
        
        </i> -->
        <b-icon v-else-if="transaction.payment.status == 'requires_capture'" 
        stacked icon="stopwatch" 
        variant="warning"></b-icon>
        <p v-else class="text-white text-uppercase">
          <b-icon stacked icon="exclamation-circle" 
          variant="danger"></b-icon>
        </p>
        </b-col>
      </b-row>
      

      

      <div class="row mb-3">
        <div class="col-md-4 pr-md-1 text-uppercase text-white">
          {{ $t("transaction.amount_capturable") + " : " + (transaction.payment ? transaction.payment.amount_capturable /100 + transaction.payment.currency || '€': 'Error!!')}}
        </div>
        <div class="col-md-4 pl-md-1 text-uppercase text-white">
           {{ $t("transaction.amount_received") + ' : ' + (transaction.payment ? transaction.payment.amount_received /100 + transaction.payment.currency || '€': 'Error!!')}}
        </div>
        <div class="col-md-4 pl-md-1 text-uppercase text-white">
           {{ $t("transaction.amount") + ' : ' + (transaction.payment ? transaction.payment.amount /100 + transaction.payment.currency || '€': 'Error!!')}}
        </div>
      </div>
      
        <!-- do a loop her on the transaction charge -->
        <div class="row" v-if="transaction.payment && transaction.payment.charges && transaction.payment.charges.data">
          <base-table
            :data="(transaction.payment.charges ? transaction.payment.charges.data : [])"
            :columns="chargeHeader"
            tbody-classes="black-table"
          >
            <columns>
              <th></th>
            </columns>
            <template slot-scope="{ row }">
              <td>
                {{row.amount / 100+  ' ' + (row.currency || '€')}} 
              </td>
              <td>
                {{row.amount_captured / 100+  ' ' + (row.currency || '€')}} 
              </td>
              <td>
                <base-button
                  type="link"
                  aria-label="get Invoice button"
                  style="color: #00f2c3"
                  @click="getInvoice(transaction.invoiceId)"
                >
                  <i class="tim-icons icon-notes"></i>
                </base-button>
                <base-button
                  type="link"
                  aria-label="Email invoice button"
                  style="color: #00f2c3"
                  @click="getInvoice(transaction.invoiceId)"
                >
                  <i class="tim-icons icon-send"></i>
                </base-button>
                <!-- <a :href="getInvoice(transaction.invoiceId)"> Invoice</a> -->
                <base-button
                  type="link"
                  aria-label="CB invoice"
                  style="color: #00f2c3"
                  @click="getCreditCardInvoice(row.receipt_url)"
                >
                  <i class="tim-icons icon-credit-card"></i>
                </base-button>

              </td>
            </template>
        </base-table>
        </div>
      <div class="row">
        <b-button variant="link"  >
          {{ $t("order.label") + transaction.orderId }}
                  <b-icon 
                    icon="box-arrow-in-up-right" 
                    aria-hidden="true" 
                    style="color: #00f2c3"
                    @click="openOrderDetail"
                    >

              </b-icon>
        </b-button>
        <!-- <div class="col-md-6 pl-md-1 text-uppercase text-white">
           {{ $t("order.label") + transaction.orderId }}
           <base-button 
           type="link" 
            style="color: #00f2c3"
           @click="openOrderDetail"
            >
              <i class="tim-icons icon-send"></i>
            </base-button>
        </div> -->
      </div>
    </card>
</template>

<script>
import Modal from "@/components/Modal";
import { BaseTable } from "@/components";

export default {
  name: "transaction-page",
  components: {
    Modal,
    BaseTable,
  },

  props: {
    showTransactionDetails: {
      type: Boolean,
      description: "show me",
    },
  },
  data() {
    return {
      currencySymb: { eur: "€", dol: "$", $: "$", "€": "€" },
      chargeHeader: [
        this.$t('transaction.amount'), 
        this.$t('transaction.amount_received'), 
        this.$t('transaction.reciept')
        ],
        transaction : {},
    };
  },
  methods: {
    close() {
      this.$emit("showTransactionDetails", false);
    },
    saveTransaction() {
      //emit saving event for this specifique order
      this.loading = true;
      this.$store.dispatch("transactions/save", this.transaction).then(
        (p) => {
          this.loading = false;
          this.transaction = p;
          this.close();
          // this.$router.push("/dashboard");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            message: this.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
          localStorage.removeItem("user");
          this.$router.push("/login");
        }
      );
      this.$emit("savetransaction", this.order);
    },
    getInvoice(invoiceId) {
      //get invoice Object 
      console.log("getting invoice of", invoiceId)
      //print 
      return "/invoices/" + invoiceId + "/"
    },
    getCreditCardInvoice(url){
      window.open(url)
    },
    openOrderDetail(){
      window.open(`/#/orders/${this.transaction.orderId}`,'_blank');
    },
    getHeader(){
      return  (this.transaction.payment && this.transaction.payment.charges.data[0]  ? this.transaction.payment.charges.data[0].billing_details.name : 'Error!!')
    },
    picStatus() {
      if (this.transaction.payment){
        return 'img/paydone_check.png'
      }else{

      }
      
    }
  },
  mounted() {
    
    //load order details if needed
    this.loading = true
    this.$store.dispatch(`transactions/get`, this.$route.params.id, this.transaction).then(
      (t) => {
        this.loading = false;
        this.transaction = t;
        console.log("transaction", t)
        // this.$router.push("/dashboard");
      },
      (error) => {
        
        this.loading = false;
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          console.log("error dispatch transaction/get", error)
        this.$notify({
          message: this.message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
        });
        localStorage.removeItem("user");
        this.$router.push("/login");
      }
    );
  },
  computed: {
    showMee: {
      get() {
        return this.showTransactionDetails;
      },
      set(check) {
        this.$emit("showTransactionDetails", check);
      },
    },
  },
};
</script>
