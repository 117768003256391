<template>
  <card type="user">
    <p class="card-text">
    </p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <img class="avatar" :src="avatarPic" ref="avatar" alt="...">
      <base-button  @click="$refs.fileInput.click()" icon round>
          <b-icon icon="camera" scale="1" ></b-icon>
      </base-button>


      <input style="display: none;"  type="file" ref="fileInput" @change="uploadPic"/>
        <h5 class="title">{{user.firstname + ' ' + user.lastname}}</h5>
      
      <p class="description text-uppercase text-primary ">
        {{user.partnerId}}
      </p>
    </div>
    <p></p>
    <p class="card-description">
      {{user.about}}
    </p>
    <!-- <div slot="footer" class="button-container">
      <base-button icon round >
        <b-icon icon="facebook" scale="1"></b-icon>
      </base-button>
      <base-button icon round class="btn-twitter">
        <b-icon icon="twitter" scale="1"></b-icon>
      </base-button>
      <base-button icon round class="btn-google">
        <b-icon icon="google" scale="1"></b-icon>
      </base-button>

    </div> -->
  </card>
</template>
<script>
  export default {
    props: {
      user: {
        type: Object,
        default: () => {
          return {};
        } 
      }
    },
    computed : {
      avatarPic(){
        //TODO: get public URL 
        return (this.user.avatar ? this.user.avatar : 'img/paydone_check.png')
      }
    },
    methods :{
      uploadPic(e){
        console.log("uploading pic")
        console.log(e)
        var formData = new FormData();
        var imagefile = e.target;
        formData.append("file", imagefile.files[0]);

        this.$store.dispatch("users/postPic", formData).then(
      (pic) => {
        this.loading = false;
        this.user.avatar = pic.url;
        this.$refs.avatar.src = pic.url
      },
      (error) => {
        this.loading = false;
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.$notify({
          message: this.message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
        });
        //localStorage.removeItem("user");
        //this.$router.push("/login");
      }
    );
      } 
    }
  }
</script>
<style>
</style>
