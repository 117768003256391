<template>
  <div>
    <order-detail
      :order="currOrder"
      :showOrderDetails="showOrderDetails"
      @showOrderDetails="showOrderDetailChanges"
      @saveOrder="saveOrder"
      id="orderDetailmodal"
    >
    </order-detail>
    <card :title="loading ? 'Loading...' : $t('order.title')">
      
       <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              :placeholder="$t('common.filter')"
            ></b-form-input>

            <b-input-group-append>
              <b-button class="btn-search" :disabled="!filter" @click="filter = ''" >X</b-button>
            </b-input-group-append>
        </b-input-group>
        
      <b-table
        striped
        hover
        responsive
        :items="orders"
        :fields="bheader"
        id="tableOrders"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        head-variant="primary"
        table-variant=""
        :busy="loading"
      >
        <template #cell(action)="row">
          <base-button
            type="link"
            aria-label="edit button"
            style="color: #00f2c3"
            @click="orderDetail(row.item)"
          >
            <i class="tim-icons icon-pencil"></i>
          </base-button>
          <base-button
            v-if="row.item.validated"
            type="link"
            aria-label="Bdc button"
            style="color: #00f2c3"
            @click="printOrder(row.item)"
          >
            <i class="tim-icons icon-bus-front-12"></i>
          </base-button>
          <base-button
            v-if="
              row.item.validated &&
              row.item.capturedAmount == row.item.totalAmount
            "
            type="link"
            aria-label="Money Captured"
            style="color: #00f2c3"
            :disabled="true"
          >
            <i class="tim-icons icon-money-coins"></i>
          </base-button>
          <base-button
            v-if="
              row.item.validated &&
              row.item.capturedAmount !== row.item.totalAmount
            "
            type="link"
            aria-label="Money Captured"
            style="color: red"
            :disabled="true"
          >
            <i class="tim-icons icon-money-coins"></i>
          </base-button>
          <b-button variant="link">
            <b-icon
              icon="box-arrow-in-up-right"
              aria-hidden="true"
              style="color: #00f2c3"
              @click="openOrderDetail(row.item)"
            >
            </b-icon>
          </b-button>
        </template>
        <template #cell(customers)="row">
          <p class="title">
            {{ row.item.customers ? row.item.customers[0].lastname : "??" }}
          </p>
          <p class="text-muted">
            {{ row.item.customers ? row.item.customers[0].firstname : "??" }}
          </p>
        </template>
        <template #cell(validated)="row">
          <base-checkbox v-model="row.item.validated" disabled> </base-checkbox>
        </template>
        <template #cell(createdAt)="row">
          <p class="title">
            {{ row.item.createdAt | formatDate }}
          </p>
        </template>
        <template #cell(amount)="row">
          <p class="title">
            {{ row.item.totalAmount ? row.item.totalAmount / 100 : 0 }}
            {{ row.item.currency ? row.item.currency : "€" }}
          </p>
          <!-- <p class="text-muted">{{ (row.item.currency?row.item.currency : '€') }}</p> -->
        </template>
        <template #cell(capturedAmount)="row">
          <p class="title">
            {{ row.item.capturedAmount ? row.item.capturedAmount / 100 : 0 }}
            {{ row.item.currency ? row.item.currency : "€" }}
          </p>
          <!-- <p class="text-muted">{{ (row.item.currency?row.item.currency : '€') }}</p> -->
        </template>
        <template #table-busy>
          <div class="row">
            <div
              class="col-md-12 pr-md-1"
              align="center"
              style="margin-bottom: 5px"
            >
              <loading :isLoading="loading"></loading>
            </div>
          </div>
        </template>
      </b-table>
      <div class="row">
        <div class="col-md-12 pr-md-1">
          <b-pagination
            v-model="currentPage"
            size="sm"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="tableOrders"
            align="center"
            :current-page="currentPage"
          ></b-pagination>
        </div>
      </div>
    </card>
    <!-- printing hidden tag -->
    <div id="printjs" style="visibility: hidden"></div>
  </div>
</template>

<script>
import { BaseTable } from "@/components";
import NotificationTemplate from "../Notifications/NotificationTemplate";
import Modal from "@/components/Modal";
import OrderDetail from "./OrderDetail";
import print from "print-js";
import { jsPDF } from "jspdf";
import { BTable, BSpinner } from "bootstrap-vue";
import printrender from "./ejprint";

import loading from "@/components/loading";

export default {
  components: {
    BaseTable,
    Modal,
    OrderDetail,
    BTable,
    loading,
    BSpinner,
  },
  data() {
    return {
      orders: [],
      bheader: [
        {
          key: "createdAt",
          label: this.$t("common.createdAt"),
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "validated",
          label: this.$t("order.closed"),
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "customers",
          label: this.$t("customer.label"),
          sortable: true,
          filterByFormatted: true,
          formatter: 'customerFullName',
        },
        {
          key: "amount",
          label: this.$t("order.totalAmount"),
          sortable: false,
          filterByFormatted: true,
          sortByFormatted: true,
        },
        {
          key: "capturedAmount",
          label: this.$t("order.recievedAmount"),
          sortable: true,
        },
        {
          key: "action",
          label: this.$t("common.action"),
          sortable: false,
        },
      ],
      showOrderDetails: false,
      currOrder: {},
      perPage:
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).options
          ? JSON.parse(localStorage.getItem("user")).options.numberLine
          : "6",
      currentPage: 1,
      filter: "",
      filterOn: ['totalAmount', 'capturedAmount'],
      sortBy: "",
      sortDesc: "",
      sortDirection: "asc",
    };
  },
  methods: {
    getCommands() {
      //here get commande for the connected user.
      this.orders = [];
    },
    orderDetail(order) {
      this.currOrder = order;
      this.showOrderDetails = true;
    },
    openOrderDetail(order) {
      window.open(`/#/orders/${order._id}`, "_blank");
    },
    showOrderDetailChanges(check) {
      this.showOrderDetails = check;
    },
    printOrder(o) {
      this.loading = true;
      document.getElementById("printjs").innerHTML = "";
      const html = printrender.getHtml(o);
      //console.log(html)
      document.getElementById("printjs").insertAdjacentHTML("beforeend", html);
      this.loading = false;
      print({
        printable: "printJS-order",
        type: "html",
        style: `.center {
            text-align: center;
            margin-left: 20px;
            margin-right: 20px;
            width:1200px;
        }

        .header {
            width: 100%;
            height: 70px;
            border-bottom: 1px solid #e4e6e8;
            color: #4e80c2;
            padding-bottom: 10px;
        }

        .content-header {
            /* display: block; */
        }

        .header-order {
            margin-top: 10px;
            width: 100%;
        }

        .header-address {
            width: 50%;
            float: left;
            text-align: left;
        }
        .contact-address {
            width: 50%;
            float: right;
            text-align: end;
        }

        .table {
            width: 80%;
            margin-bottom: 1rem;
            background-color: transparent;
            display: table;
            border-collapse: separate;
            box-sizing: border-box;
            text-indent: initial;
            border-spacing: 2px;
            border-color: grey;
            padding-left: 10px;
            padding-right: 10px;
            
        }
        .td{
          border-color: grey;
          border-bottom: 1px solid #e4e6e8;
        }
        .th{
          border-color: grey;
          border-bottom: 1px solid #4e80c2;
          background-color : #e3e3e3;
        }
        .thead {
            border-bottom-width: 1px;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 700;
            border: 0;
            color: white;
            background-color: rgba(2, 153, 157, 0.7);
        }
        .footer{
          position: fixed;
          left: 0;
          bottom: 0;
          width: 100%;
          background-color: black;
          color: #4e80c2;
          text-align: center;
          border-top-width:1px;
          border-top: 1px solid #e4e6e8;
        }
        `,
      });
    },
    getPDF(o) {
      var doc = new jsPDF();
      var elementHandler = {
        "#ignorePDF": function (element, renderer) {
          return true;
        },
      };

      doc.output("dataurlnewwindow");
    },
    saveOrder(o) {
      this.$notify({
        message: "saved",
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "success",
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
     //console.log("filteredItems", filteredItems);
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    customerFullName(value, key, item){
      console.log("value", value)
      return `${value.firstname} ${value.lastname}`
    }
  },
  created() {
    this.loading = true;
    this.$store.dispatch("orders/list", this.orders).then(
      (o) => {
        this.loading = false;
        this.orders = o;
      },
      (error) => {
        this.loading = false;
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("error dispatch order/list", error);
        this.$notify({
          message: this.message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
        });
        localStorage.removeItem("user");
        console.log("pushing to login");
        //this.$router.push("/dashboard");
      }
    );
  },
  computed: {
    rows() {
      return this.orders.length;
    },
  },
};
</script>

<style scoped>
.btn-search {
  border-color: rgba(2, 153, 157, 0.8);
  /* border: 1px; */
  border-style: solid;
  margin-top:-1px
}
</style>