<template>
  <div>
    <div class="row">
      <div class="col-12" style="padding:0;">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
                <h5 v-if="!loadingStatsOrders" class="card-category">
                  {{ $t("dashboard.statsOrders") }}
                </h5>
                <h2 v-if="!loadingStatsOrders" class="card-title">
                  {{ $t("dashboard.performance") }}
                </h2>
                <loading :isLoading="this.loadingStatsOrders"></loading>
              </div>

              <div class="col-sm-6">
                <div
                  class="btn-group btn-group-toggle"
                  :class="isRTL ? 'float-left' : 'float-right'"
                  data-toggle="buttons"
                >
                  <label
                    v-for="(option, index) in bigLineChartCategories"
                    :key="option"
                    class="btn btn-sm btn-primary btn-simple"
                    :class="{ active: bigLineChart.activeIndex === index }"
                    :id="index"
                  >
                    <input
                      type="radio"
                      @click="initBigChart(index)"
                      name="options"
                      autocomplete="off"
                      :checked="bigLineChart.activeIndex === index"
                    />
                    {{ option }}
                  </label>
                </div>
              </div>
            </div>
          </template>
          <div class="chart-area" style="padding : 0px;">
            <line-chart
              style="height: 100%"
              chart-id="big-line-chart"
              :chart-data="bigLineChart.chartData"
              :gradient-colors="bigLineChart.gradientColors"
              :gradient-stops="bigLineChart.gradientStops"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>
          </div>
        </card>
      </div>
    </div>
    <div class="row mt-1">
      <!-- <div class="col-lg-4" :class="{'text-right': isRTL}" style="padding:0;">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('dashboard.totalShipments')}}</h5>
            <h3 class="card-title"><i class="tim-icons icon-bell-55 text-primary "></i> 763,215</h3>
          </template>
          <div class="chart-area">
            <line-chart style="height: 100%"
                        chart-id="purple-line-chart"
                        :chart-data="purpleLineChart.chartData"
                        :gradient-colors="purpleLineChart.gradientColors"
                        :gradient-stops="purpleLineChart.gradientStops"
                        :extra-options="purpleLineChart.extraOptions">
            </line-chart>
          </div>
        </card>
      </div> -->
      <div class="col-lg-6" :class="{ 'text-right': isRTL }" style="padding:0;">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{ $t("dashboard.nbOrders") }}</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-delivery-fast text-info"></i> {{  this.blueBarChart.chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0) }}
            </h3>
          </template>
          <div class="chart-area">
            <bar-chart
              style="height: 100%"
              chart-id="blue-bar-chart"
              :chart-data="blueBarChart.chartData"
              :gradient-stops="blueBarChart.gradientStops"
              :extra-options="blueBarChart.extraOptions"
              ref="blueBarChart"
            >
            </bar-chart>
          </div>
        </card>
      </div>
      <div class="col-lg-6" :class="{ 'text-right': isRTL }" style="padding-left:2px;">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{ $t("dashboard.totalCapturedAmount") }}</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-send text-success"></i> 
              {{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.purpleLineChart.chartData.datasets[0].data.reduce((partialSum, a) => partialSum + a, 0)) 
              }}
            </h3>
          </template>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              chart-id="purple-line-chart"
              :chart-data="purpleLineChart.chartData"
              :gradient-stops="purpleLineChart.gradientStops"
              :extra-options="purpleLineChart.extraOptions"
            >
            </line-chart>
          </div>
        </card>
      </div>
    </div>
    <!-- <div class="row mt-1">
      <div class="col-lg-12 col-md-12">
        <card type="tasks" :header-classes="{ 'text-right': isRTL }">
          <template slot="header">
            <h6 class="title d-inline">
              {{ this.$t("dashboard.tasks", { count: 5 }) }}
            </h6>
            <p class="card-category d-inline">
              {{ this.$t("dashboard.today") }}
            </p>
            <base-dropdown
              menu-on-right=""
              tag="div"
              title-classes="btn btn-link btn-icon"
              aria-label="Settings menu"
              :class="{ 'float-left': isRTL }"
            >
              <i slot="title" class="tim-icons icon-settings-gear-63"></i>
              <a class="dropdown-item" href="#pablo">{{
                this.$t("dashboard.dropdown.action")
              }}</a>
              <a class="dropdown-item" href="#pablo">{{
                this.$t("dashboard.dropdown.anotherAction")
              }}</a>
              <a class="dropdown-item" href="#pablo">{{
                this.$t("dashboard.dropdown.somethingElse")
              }}</a>
            </base-dropdown>
          </template>
          <div class="table-full-width table-responsive">
            <task-list></task-list>
          </div>
        </card>
      </div>
    </div> -->
     <!-- <div class="col-lg-6 col-md-12">
        <card class="card" :header-classes="{ 'text-right': isRTL }">
          <h4 slot="header" class="card-title">
            {{ this.$t("dashboard.simpleTable") }}
          </h4>
          <div class="table-responsive">
            <user-table></user-table>
          </div>
        </card>
      </div> -->
  </div>
</template>
<script>
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import * as chartConfigs from "@/components/Charts/config";
import TaskList from "./Dashboard/TaskList";
import UserTable from "./Dashboard/UserTable";
import config from "@/config";
import loading from "@/components/loading";
export default {
  components: {
    LineChart,
    BarChart,
    TaskList,
    UserTable,
    loading,
  },

  data() {
    return {
      bigLineChart: {
        allData: [
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [{}],
          labels: [
            
          ],
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: [],
      },
      purpleLineChart: {
        extraOptions: chartConfigs.purpleChartOptions,
        chartData: {
          labels: ["??", "??", "??"],
          datasets: [
            {
              label: this.$t("dashboard.totalCapturedAmount"),
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0, 0, 0],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.2, 0],
      },
      greenLineChart: {
        extraOptions: chartConfigs.greenChartOptions,
        chartData: {
          labels: ["JUL", "AUG", "SEP", "OCT", "NOV"],
          datasets: [
            {
              label: "My First dataset",
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: config.colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [90, 27, 60, 12, 80],
            },
          ],
        },
        gradientColors: [
          "rgba(66,134,121,0.15)",
          "rgba(66,134,121,0.0)",
          "rgba(66,134,121,0)",
        ],
        gradientStops: [1, 0.4, 0],
      },
      blueBarChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: [
            this.$t("months.jan"),
            this.$t("months.feb"),
            this.$t("months.mar"),
            this.$t("months.apr"),
            this.$t("months.may"),
            this.$t("months.jun"),
            this.$t("months.jul"),
            this.$t("months.aug"),
            this.$t("months.sep"),
            this.$t("months.oct"),
            this.$t("months.nov"),
            this.$t("months.dec"),
          ],
          datasets: [
            {
              label: this.$t('dashboard.nbOrders'),
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
      },
      loading: true,
      loadingStatsOrders: true,
      statsOrders: [],
    };
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return this.$t("dashboard.chartCategories");
    },
    getMonthLabels() {
      return [
        this.$t("months.jan"),
        this.$t("months.feb"),
        this.$t("months.mar"),
        this.$t("months.apr"),
        this.$t("months.may"),
        this.$t("months.jun"),
        this.$t("months.jul"),
        this.$t("months.aug"),
        this.$t("months.sep"),
        this.$t("months.oct"),
        this.$t("months.nov"),
        this.$t("months.dec"),
      ];
    },
  },
  methods: {
    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            fill: true,
            borderColor: config.colors.colors[index],
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.colors[index],
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.colors[index],
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.bigLineChart.allData[index],
          },
        ],
        labels: this.getMonthLabels,
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
    initBlueBarChart(datas){
      const chartData = {
          labels: this.getMonthLabels,
          datasets: [
            {
              label: this.$t('dashboard.nbOrders'),
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: datas,
            },
          ],
        }
      this.blueBarChart.chartData = chartData
      const monthNbr = new Date().getMonth()
      const Labels = this.getMonthLabels.filter((e,i)=> i<= monthNbr && i> monthNbr - 3)
      this.purpleLineChart.chartData.labels = Labels
    },
    initPurpleLineChart(datas){
      const monthNbr = new Date().getMonth()
      const Labels = this.getMonthLabels.filter((e,i)=> i<= monthNbr && i> monthNbr - 3)
      const chartData = {
          labels: Labels,
          datasets: [
            {
              label: this.$t("dashboard.totalCapturedAmount"),
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: datas,
            },
          ],
        }
      this.purpleLineChart.chartData = chartData
    }
  },
  mounted() {
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = "ar";
      this.$rtl.enableRTL();
    }
    (this.loadingStatsOrders = true),
      this.$store.dispatch("orders/stats").then(
        async (stats) => {
          this.loadingStatsOrders = false;
          this.statsOrders = stats;
          const purpleDatas = []
          const blueDatas = []
          this.statsOrders.forEach((element, idx, idxArr) => {
            const monthNbr = new Date().getMonth()
            if (element._id > monthNbr - 3 && element._id <= monthNbr){
              purpleDatas[element._id - (monthNbr-2) ] = element.totalCaptured / 100
            }
            
            this.bigLineChart.allData[2][element._id] = element.Nombre;
            blueDatas[element._id]= element.Nombre;
            this.bigLineChart.allData[0][element._id] =
              element.totalAmount / 100;
            this.bigLineChart.allData[1][element._id] =
              element.totalCaptured / 100;
            if (idxArr.length - 1 == idx) {
              this.initBigChart(0);
              this.initBlueBarChart(blueDatas)
              this.initPurpleLineChart(purpleDatas)
             }
          });
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log("error dispatch order/stats", error);
          this.$notify({
            message: this.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
          localStorage.removeItem("user");
          this.$router.push("/login");
        }
      );
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = "en";
      this.$rtl.disableRTL();
    }
  },
};
</script>
<style>
</style>
