<template>
  <div class="row">
    <div class="col-md-8">
      <edit-profile-form :model="user"> </edit-profile-form>
    </div>
    <div class="col-md-4">
      <user-card :user="user"></user-card>
    </div>
  </div>
</template>
<script>
import EditProfileForm from "./Profile/EditProfileForm";
import UserCard from "./Profile/UserCard";
export default {
  components: {
    EditProfileForm,
    UserCard,
  },
  data() {
    return {
      loading : true,
      user: JSON.parse(localStorage.getItem('user')),
    };
  },
  methods:{
    
  },
  created() {
    this.loading = true
    this.$store.dispatch("users/get", this.user.id).then(
      (u) => {
        this.loading = false;
        this.user = u;
        if(!this.user.address) this.user.address ={}
      },
      (error) => {
        this.loading = false;
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.$notify({
          message: this.message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
        });
        //localStorage.removeItem("user");
        //this.$router.push("/login");
      }
    );
  },
};
</script>
<style>
</style>
