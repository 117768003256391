<template>
  <base-table :data="table.data"
              :columns="table.columns"
              thead-classes="text-primary">
  </base-table>
</template>
<script>
  import { BaseTable } from "@/components";
  export default {
    components: {
      BaseTable
    },
    computed: {
      table(){
        return {data : [{col1 : 11, col2 : 12,col3 : 13},{col1 : 21, col2 : 22,col3 : 23}], columns : ['Col1','Col2','Col3']};
      }
    }
  }
</script>
<style>
</style>
