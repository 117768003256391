<template>
  <b-contaier class="mx-5">
    <b-row>
        <b-card
          overlay
          text-variant="white"
          class="text-center rounded paydone-card"
          img-src="./img/17.jpeg"
          title="Image Overlay"
          sub-title="VISA"
        >
        <template #header>
            <h4 class="mb-0">Hello World</h4>
        </template>
        <b-card-header>
            gddeded
        </b-card-header>
          <b-card-body body-class="text-center">
              <h2>
                   {{ 'XXXX-XXXX-XXXX-'}}
              </h2> 
          </b-card-body>
          <b-card-text>
            <h3 class="text-uppercase text-center"></h3>
          </b-card-text>
          <b-card-footer>
              <b-row>
              <span>
                  sdsds
              </span>
              <span>
                  sdsds
              </span>
          </b-row> 
          </b-card-footer>
          
        </b-card>
    </b-row>
  </b-contaier>
</template>
<script>
export default {};
</script>
<style scoped>
.paydone-card {
  width: 400px;
  height: 220px;
}
</style>