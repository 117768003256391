<template>
  <base-table
    :data="products"
    :columns="productsheader"
    tbody-classes="light-table"
  >
    <columns>
      <th></th>
    </columns>
    <template slot-scope="{ row }" v-if="row.qty > 0" :class="{'nonvisbile' : raw.qty >0}">
        <td >
        <base-input class="title">{{ row.id }}</base-input>
      </td>
      <td >
        <base-input v-model="row.qty" :disabled="readOnly"> </base-input>
      </td>
      <td >
        <base-input class="title" disabled="true" >{{ getPriceDisplay(row._id) }}</base-input>
      </td>
      <td >
        <base-input class="title" disabled="true" >{{ getTotalDisplay(row._id) }}</base-input>
      </td>
      
    </template>
  </base-table>
</template>
<script>
import { BaseTable } from "@/components";

export default {
  components: {
    BaseTable,
  },
  props: {
    products: {
      Type: Array,
      description: "products in this order for the customer",
    },
    "v-b-toggle": {
      Type: String,
      description: "associated products",
    },
    readOnly : Boolean,
  },
  data() {
    const name = this.$t("product.name")
    return {
      productsheader: [name, this.$t("product.qty"),  this.$t("product.price"), this.$t("product.total")],
    };
  },
  methods : {
    getTotalDisplay(id){
      const p = this.products.find(p=> p._id == id)
      this.$emit("qtyUpdate", p);
      return (p.price * p.qty/100) + '€'
    },
    getPriceDisplay(id){
      const p = this.products.find(p=> p._id == id)
      //console.log(p)
      return (p.price/100) + '€'
    }
  }
};
</script>

<style lang="css" scoped>
.black-table {
  background-color: #27293d;
}
.nonvisbile{
  visibility: hidden;
}
</style>