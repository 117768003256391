<template>
  <card :title="$t('partner.title')">
    <partner-detail
      :partner="currPart"
      :showPartnerDetails="showPartnerDetails"
      @showPartnerDetails="showPartnerDetailChanges"
      @savePartner="savePartner"
    >
    </partner-detail>
    <div class="row">
        <div class="col-md-12 pr-md-1" align="center" style="margin-bottom:5px;">
            <loading :isLoading="loading"></loading>
        </div>
      </div>
    <b-table
      striped
      hover
      :items="partners"
      :fields="bheader"
      id="tablePartner"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(action)="row">
        <base-button
          type="link"
          aria-label="edit button"
          style="color: #00f2c3"
          @click="partnerDetail(row.item)"
        >
          <i class="tim-icons icon-pencil"></i>
        </base-button>
      </template>
      <template #cell(address)="row">
        <p class="title">
          {{ row.item.address.zipCode }}
        </p>
        <p class="text-muted">
          {{ row.item.address.city + " " + row.item.address.country }}
        </p>
      </template>
      <template #cell(name)="row">
        <p class="title">
          {{ row.item.name }}
        </p>
        <p class="text-muted">{{ row.item.description }}</p>
      </template>
      <template #cell(createdAt)="row">
        <p class="title">
          {{ row.item.createdAt | formatDate }}
        </p>
      </template>
      <template #cell(thresholdAmount)="row">
        <p class="title">
          {{ row.item.thresholdAmount ? row.item.thresholdAmount / 100 : 0 }}
        </p>
        <p class="text-muted">{{ row.item.currency }}</p>
      </template>
    </b-table>
    <div class="row">
      <div class="col-md-12 pr-md-1">
        <b-pagination
          v-model="currentPage"
          size="sm"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="tablePartner"
          align="center"
          :current-page="currentPage"
        ></b-pagination>
      </div>
    </div>
  </card>
</template>

<script>
import { BaseTable } from "@/components";
import NotificationTemplate from "../Notifications/NotificationTemplate";
import Modal from "@/components/Modal";
import PartnerDetail from "./partnerDetail";
import { BTable } from "bootstrap-vue";

import loading  from "@/components/loading";

export default {
  components: {
    BaseTable,
    Modal,
    PartnerDetail,
    BTable,
    loading,
  },
  data() {
    return {
      partners: [],
      currentPage: 1,
      bheader: [
        {
          key: "createdAt",
          label: "Date",
          sortable: true,
          class: "title",
        },
        {
          key: "name",
          labe: this.$t('partner.companyName'),
          sortable: false,
          _rowVariant: "title",
        },
        {
          key: "address",
          label: this.$t('address.label'),
        },
        {
          key: "thresholdAmount",
          label : this.$t('partner.threshold'),
          sortable: false,
        },
        {
          key: "action",
          label : this.$t('common.action'),
          sortable: false,
        },
      ],
      showPartnerDetails: false,
      currPart: {},
      perPage:JSON.parse(localStorage.getItem("user")) && JSON.parse(localStorage.getItem("user")).options ? JSON.parse(localStorage.getItem("user")).options.numberLine :"6",
      currentPage:1,
      loading : true,
    };
  },
  methods: {
    getPartners() {
      //here get commande for the connected user.
      this.partners = [];
    },
    partnerDetail(partner) {
      this.currPart = partner;
      this.showPartnerDetails = true;
    },

    showPartnerDetailChanges(check) {
      console.log("changing partner details");
      this.showPartnerDetails = check;
    },
    savePartner(o) {

      this.$notify({
        message: this.$t('partner.saved'),
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "success",
      });
    },
  },
  created() {
    this.loading = true
    this.$store.dispatch("partners/list", this.partners).then(
      (c) => {
        this.loading = false;
        this.partners = c;
      },
      (error) => {
        this.loading = false;
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.$notify({
          message: this.message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
        });
        localStorage.removeItem("user");
        //this.$router.push("/login");
      }
    );
  },
  computed: {
    rows() {
      return this.partners.length;
    },
  },
};
</script>

<style >
</style>