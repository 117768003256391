<template>
  <div class="col-md-12">    
    <b-list-group>
      <div>
      <b-list-group-item
          class="d-flex align-items-center mb-2"
        >
          <b-button
            v-b-toggle="'collapse-'"
            :pill="true"
            size="sm"
            class="mr-3 btn-icon btn-simple btn-round"
          >
          <span class="when-open"><b-icon
              icon="chevron-up"
              :v-b-toggle="'collapse-'"
            >
            </b-icon>
            </span>
            
            <span class="when-closed"><b-icon
              icon="chevron-down"
              :v-b-toggle="'collapse-'"></b-icon></span>
          </b-button>
          <span class="mr-auto" :v-b-toggle="'collapse-'">{{
            $t('product.title')
          }}</span>
          <b-icon v-if="transaction.payment && !transaction.payment.status "   icon="shield-fill-x" variant="danger" class="mr-auto"></b-icon>
          <!-- <b-badge>{{
            transaction.numberItems
          }} article(s)</b-badge>
          <b-badge variant="warning" class="ml-2"
            > {{ transaction.toBeCapturedAmount / 100}}€
          </b-badge>
          <b-badge variant="primary" class="ml-2"
            > {{ transaction.capturedAmount /100}} €
          </b-badge>
          <b-badge variant="danger" class="ml-2" v-if="transaction.amount - transaction.capturedAmount>0"
            > {{ (transaction.amount - transaction.capturedAmount) /100}} €
          </b-badge> -->
          

        </b-list-group-item>
        <b-collapse :id="'collapse-'">
          <b-list-group-item>
            <div
              class="mb-2"
            >
              <products-customer-order
                :products="transaction.products.filter(p => p.qty >0)" 
                class="mb-0"
                v-if="displayErrors || (!displayErrors && (transaction.payment && transaction.payment.status))"
                :class="{
                        'onError' : (!transaction.payment || (transaction.payment && !transaction.payment.status)),
                        'notCaptured': (transaction.payment.amount_received != transaction.amount), 
                        'captured' :  ( transaction.payment.amount_received == transaction.amount) }"
                @qtyUpdate="qtyUpdate"
                :readOnly="readOnly"
              >
              </products-customer-order>
            </div>
          </b-list-group-item>
        </b-collapse>
      </div>
    </b-list-group>
  </div>
</template>
<script>
import { BaseTable } from "@/components";
import BaseButton from "../../components/BaseButton.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";
import { BIcon, BFormCheckbox, BFormCheckboxGroup  } from "bootstrap-vue";
import ProductsCustomerOrder from '../order/ProductsCustomerOrder.vue'

export default {
  components: {
    BaseTable,
    BaseInput,
    BaseButton,
    BIcon,
    ProductsCustomerOrder,
    BFormCheckbox,
    BFormCheckboxGroup
  },
  props: {
    transaction: {
      type: Object,
      description: "Transaction with products",
    },
    readOnly: {
        type: Boolean,
        default: true
    },
  },
  data() {
    return {
      productsVisible: {},
      displayErrors : false,
    };
  },
  methods: {
    qtyUpdate(product) {
      this.$emit("qtyUpdate", product);
    },
  },
  mounted: function () {
    //this.$watch(this.productsVisible, function(){console.log('a thing changed')}, {deep : true});
    
  },
};
</script>
<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.captured {
  background-color:#27293d;
}
.notCaptured {
  background-color: rgb(236, 137, 24);
}
.onError {
  background-color: rgb(202, 17, 27);
}
.custom-control-label::before{
  border: green !important;
    border-style: solid !important;
}
.paydone-switch::before, .paydone-switch::after {
  border: green;
    border-style: solid;
}
</style>