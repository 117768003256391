<template>
  <modal
    :show.sync="showMee"
    id="partnerModal"
    :centered="true"
    :show-close="true"
    gradient="primary"
    footerClasses="modal-footer"
  >
    <card>
        <div class="row">
          <div class="col-md-10 pr-md-1">
            <h1 slot="header" class="text-uppercase text-center">
                {{ (partner.name ? partner.name : $t('partner.unknown')) }}
                <p class="text-muted text-lowercase">
                  ({{ partner.id }})
                </p>
            </h1>
            
            <p class="text-white">
              {{ $t('partner.siren')}} : {{ (partner.siren ?   partner.siren :$t('partner.requiredData') )}}
            </p>
            <p class="text-white">
              {{ $t('partner.numRCS')}}  : {{ (partner.numRCS ?  partner.numRCS :$t('partner.requiredData')) }}
            </p>
            <p class="text-white">
              {{ $t('partner.contact')}}  : {{ (partner.email ?  partner.email :$t('partner.requiredData') )}}
            </p>
          </div>
          <div class="col-md-2 pr-md-1 float-right" >
            
            <input style="display: none;"  type="file" ref="fileInput" @change="uploadLogo"/>
            <b-img width="100px" height="100px"  :src="logoPic" alt="Partner" ref="logo" rounded="circle" right class="float-right">
       
            </b-img>
              <base-button  @click="$refs.fileInput.click()" icon round>
              <b-icon icon="camera" scale="1" ></b-icon>
            </base-button>
            
          </div>
          
        </div>
      
      <b-tabs  >
        <b-tab :title="$t('partner.general')" class="border-left border-right border-bottom border-light pt-2">
          <div class="row">
            <div class="col-md-6 pr-md-1">
              <base-input
                :label="$t('partner.companyName')"
                v-model="partner.name"
                :placeholder="$t('partner.companyName')"
                required
              >
              </base-input>
            </div>
            <div class="col-md-6 pl-md-1">
              <base-input
                :label="$t('partner.threshold')"
                v-model="partner.thresholdAmount"
                :placeholder="$t('partner.threshold')"
              >
              </base-input>
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-6 pr-md-1">
              <base-input
                :label="$t('partner.siren')"
                v-model="partner.siren"
                :placeholder="$t('partner.siren')"
              >
              </base-input>
            </div>
            <div class="col-md-6 pl-md-1">
              <base-input
                :label="$t('partner.siret')"
                v-model="partner.siret"
                :placeholder="$t('partner.siret')"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 pr-md-1">
              <base-input
                :label="$t('partner.codeNaf')"
                v-model="partner.codeNaf"
                :placeholder="$t('partner.codeNaf')"
              >
              </base-input>
            </div>
            <div class="col-md-6 pl-md-1">
              <base-input
                :label="$t('partner.capitalSocial')"
                v-model="partner.capitalSocial"
                :placeholder="$t('partner.capitalSocial')"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 pr-md-1">
              <base-input
                :label="$t('partner.legalType')"
                v-model="partner.formeJuridique"
                :placeholder="$t('partner.legalType')"
              >
              </base-input>
            </div>
            <div class="col-md-6 pl-md-1">
              <base-input
                :label="$t('partner.numRCS')"
                v-model="partner.numRCS"
                :placeholder="$t('partner.numRCS')"
              >
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 pr-md-1">
              <base-input
                :label="$t('partner.companyLegalCity')"
                v-model="partner.villeGreffe"
                :placeholder="$t('partner.companyLegalCity')"
              >
              </base-input>
            </div>
            <div class="col-md-6 pl-md-1">
              <base-input
                :label="$t('partner.ImmatDepartment')"
                v-model="partner.departementImmatriculation"
                :placeholder="$t('partner.ImmatDepartment')"
              >
              </base-input>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('partner.contact')" class="border-left border-right border-bottom border-light pt-2">
          <div class="row">
            <div class="col-md-6 pr-md-1">
              <base-input
                :label="$t('partner.email')"
                v-model="partner.email"
                :placeholder="$t('partner.email')"
              >
              </base-input>
            </div>
            <div class="col-md-6 pl-md-1">
              <base-input
                :label="$t('partner.phone')"
                v-model="partner.phone"
                :placeholder="$t('partner.phone')"
              >
              </base-input>
            </div>
          </div>
          <paydone-address :address="partner.address" language="fr">

          </paydone-address>
        </b-tab>
        <b-tab
          :title="$t('partner.customization')"
          class="border-left border-right border-bottom border-light pt-2"
        >
          <div class="row">
            <div class="col-md-6">
              <base-input
                :label="$t('partner.primaryColor')"
                v-model="partner.primaryColor"
                :placeholder="$t('partner.primaryColor')"
              >
              </base-input>
            </div>
            <div class="col-md-6">
              <base-input
                :label="$t('partner.primaryColor')"
                v-model="partner.secondaryColor"
                :placeholder="$t('partner.primaryColor')"
              >
              </base-input>
            </div>
          </div>

          <div class="row">
            <!-- <div class="col-md-3">
              <base-input :label="$t('partner.logo')" v-model="partner.logo" type="file">
              </base-input>
            </div> -->
            <div class="col-md-3">
              <base-input :label="$t('partner.kv')" v-model="partner.kv" type="file">
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <base-input :label="$t('partner.baseline')" v-model="partner.description">
              </base-input>
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <div class="row">
        <div class="col-md-6 px-4">
          <base-button type="primary" block @click="savePartner"
            >{{$t('common.save')}}</base-button
          >
        </div>
        <div class="col-md-6 px-4">
          <base-button type="secondary" block @click="close"
            >{{$t('common.cancel')}}</base-button
          >
        </div>
      </div>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import paydoneAddress from "@/components/address"

export default {
  name: "partner-detail",
  components: {
    Modal,
    paydoneAddress,
  },

  props: {
    partner: {
      type: Object,
      description: "Current partner",
    },
    showPartnerDetails: {
      type: Boolean,
      description: "show me",
    },
  },
  data() {
    return {
      currencySymb: { eur: "€", dol: "$", $: "$", "€": "€" },
    };
  },
  methods: {
    close() {
      this.$emit("showPartnerDetails", false);
    },
    savePartner() {
      this.loading = true;
      console.log("Partner beofre saving : ", this.partner)
      this.$store.dispatch("partners/save", this.partner).then(
        (p) => {
          this.loading = false;
          this.partner = p;
          this.close();
          // this.$router.push("/dashboard");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            message: this.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
          ("user");
          this.$router.push("/login");
        }
      );
      this.$emit("savePartner", this.partner);
    },
    getPrimaryColor() {
      return this.partner.pimaryColor || "#27293d";
    },
    uploadLogo(e){
        var formData = new FormData();
        var imagefile = e.target;
        formData.append("file", imagefile.files[0]);
        this.$store.dispatch("partners/postlogo", formData).then(
      (pic) => {
        this.loading = false;
        console.log("pic", pic)
        this.partner.logo = pic.url;
        this.$refs.logo.src = pic.url
      },
      (error) => {
        this.loading = false;
        //alert("error here", error)
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.$notify({
          message: this.message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
        });
        //localStorage.removeItem("user");
        //this.$router.push("/login");
      }
    );
    }
  },
  mounted() {},
  computed: {
    showMee: {
      get() {
        return this.showPartnerDetails;
      },
      set(check) {
        this.$emit("showPartnerDetails", check);
      },
    },
      logoPic(){
        //TODO: get public URL 
        return (this.partner.logo ? this.partner.logo : 'img/paydone_check.png')
      }
  },
};
</script>

<style>
.float-right{
  float: right;
}
</style>
