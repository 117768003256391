<template>
  <div class="col-md-12">
    <div class="row">
      
      <h4 class="col-md-8">{{ $t("customer.label") }} </h4>
      
      <b-form-checkbox v-model="displayErrors" value="true" class="col-md-4 paydone-switch">
        {{ $t('transaction.displayerror')}} 
    </b-form-checkbox>
  
  </div>
    <b-list-group>
      <div
        v-for="(customer, index) in customers"
        v-bind:key="index"
      >
        <b-list-group-item
          class="d-flex align-items-center mb-2"
          v-if="displayErrors || (!displayErrors && (customer.toBeCapturedAmount || customer.capturedAmount ))">
          <b-button
            v-b-toggle="'collapse-' + index"
            :pill="true"
            size="sm"
            class="mr-3 btn-icon btn-simple btn-round"
          >
          <span class="when-open"><b-icon
              icon="chevron-up"
              :v-b-toggle="'collapse-' + index"
            >
            </b-icon>
            </span>
            
            <span class="when-closed"><b-icon
              icon="chevron-down"
              :v-b-toggle="'collapse-' + index"
            ></b-icon></span>
          </b-button>
          <span class="mr-auto" :v-b-toggle="'collapse-' + index">{{
            (customer.firstname ? customer.firstname.toUpperCase() + " " : "" ) + (customer.lastname ? customer.lastname.toUpperCase() : "")
          }}</span>
          <b-icon v-if="customer.transactions[0].payment && !customer.transactions[0].payment.status "   icon="shield-fill-x" variant="danger" class="mr-auto"></b-icon>
          <b-badge>{{
            customer.numberItems
          }} article(s)</b-badge>
          <b-badge variant="warning" class="ml-2"
            > {{ customer.toBeCapturedAmount / 100}}€
          </b-badge>
          <b-badge variant="primary" class="ml-2"
            > {{ customer.capturedAmount /100}} €
          </b-badge>
          <b-badge variant="danger" class="ml-2" v-if="customer.amount - customer.capturedAmount>0"
            > {{ (customer.amount - customer.capturedAmount) /100}} €
          </b-badge>
          <b-icon icon="box-arrow-in-up-right" class="ml-2"> </b-icon>
          

        </b-list-group-item>
        <b-collapse :id="'collapse-' + index">
          <b-list-group-item>
            <div
              v-for="(tx, index) in customer.transactions"
              v-bind:key="index"
              class="mb-2"
            >
              <products-customer-order
                :products="tx.products.filter(p => p.qty >0)" 
                class="mb-0"
                v-if="displayErrors || (!displayErrors && (tx.payment && tx.payment.status))"
                :class="{
                        'onError' : (!tx.payment || (tx.payment && !tx.payment.status)),
                        'notCaptured': (tx.payment.amount_received != tx.amount), 
                        'captured' :  ( tx.payment.amount_received == tx.amount) }"
                @qtyUpdate="qtyUpdate"
                :readOnly="readOnly"
              >
              </products-customer-order>
            </div>
          </b-list-group-item>
        </b-collapse>
      </div>
    </b-list-group>
  </div>
</template>
<script>
import { BaseTable } from "@/components";
import BaseButton from "../../components/BaseButton.vue";
import BaseInput from "../../components/Inputs/BaseInput.vue";

import { BIcon, BFormCheckbox,BFormCheckboxGroup  } from "bootstrap-vue";
import ProductsCustomerOrder from "./ProductsCustomerOrder.vue";
export default {
  components: {
    BaseTable,
    BaseInput,
    BaseButton,
    BIcon,
    ProductsCustomerOrder,
    BFormCheckbox,
    BFormCheckboxGroup
  },
  props: {
    customers: {
      type: Array,
      description: "Customers lists with products",
    },
    readOnly: Boolean,
  },
  data() {
    return {
      productsVisible: {},
      displayErrors : false,
    };
  },
  methods: {
    qtyUpdate(product) {
      this.$emit("qtyUpdate", product);
    },
     getCustomerToCaptureAmount(customer){
      const toBeCapturedAmount =  customer.transactions.filter(tx => {
          if(tx.payment && tx.payment.status == 'requires_capture') 
          return tx.amount
        })
      const amount =   toBeCapturedAmount.reduce((accumulator, a, idx) =>{
        return accumulator = accumulator + a.amount;
      },0) /100
      return amount
    },
    getCustomerRecievedAmount(customer){
       const recievedAmount =   customer.transactions.filter(tx => {
        if (tx.payment && tx.payment.status == 'succeeded') 
        return tx.amount
        })
      const amount =   recievedAmount.reduce((accumulator, a, idx) =>{
        return accumulator = accumulator + a.amount;
      },0) /100
      return amount

    },
  },
  mounted: function () {
    //this.$watch(this.productsVisible, function(){console.log('a thing changed')}, {deep : true});
  },
};
</script>
<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.captured {
  background-color:#27293d;
}
.notCaptured {
  background-color: rgb(236, 137, 24);
}
.onError {
  background-color: rgb(202, 17, 27);
}
.custom-control-label::before{
  border: green !important;
    border-style: solid !important;
}
.paydone-switch::before, .paydone-switch::after {
  border: green;
    border-style: solid;
}
</style>