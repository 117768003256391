<template>
  <modal
    :show.sync="showMee"
    id="productModal"
    :centered="false"
    :show-close="true"
    gradient="primary"
    footerClasses="modal-footer"
  >
    <card>
      <h1 slot="header" class="text-uppercase text-center">
        {{ product.name  || product.id }}{{ (product.externalId? ' - ' + product.externalId: "") }}
      </h1>

      <div class="row">
        <div class="col-md-6 pr-md-1">
          <base-input
            :label="$t('product.name')"
            v-model="product.name"
            :placeholder="$t('product.name')"
          >
          </base-input>
        </div>
        <div class="col-md-6 pl-md-1">
          <base-input
            :label="$t('product.price')"
            v-model="product.price"
            :placeholder="$t('product.price')"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 pr-md-1">
          <base-input
            :label="$t('product.externalId')"
            v-model="product.externalId"
            :placeholder="$t('product.externalId')"
          >
          </base-input>
        </div>
        <div class="col-md-6 pl-md-1">
          <base-input
          :label="$t('product.description')"
            v-model="product.description"
            :placeholder="$t('product.description')"
          >
          </base-input>
        </div>
      </div>
       <div class="row">
        <div class="col-md-6 pr-md-1">
          <base-input
            :label="$t('product.taxRate')"
            v-model="product.taxRate"
            :placeholder="$t('product.taxRate')"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <base-input
            :label="$t('product.image')"
            v-model="product.image"
            :placeholder="$t('product.image')"
          >
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input
            :label="$t('product.image2')"
            v-model="product.image2"
            :placeholder="$t('product.image2')"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
          <div class="col-md-12">
              <base-input 
              :label="$t('product.longdesc')"
              v-model="product.longdesc">
          </base-input>
          </div>
      </div>
      <div class="row">
        <div class="col-md-4 px-4">
          <base-button type="primary" block @click="saveProduct"
            >{{ $t('common.save')}}</base-button
          >
        </div>
        <div class="col-md-4 px-4">
          <base-button type="secondary" block @click="close"
            >{{ $t('common.cancel')}}</base-button
          >
        </div>
      </div>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "product-detail",
  components: {
    Modal,
  },

  props: {
    product: {
      type: Object,
      description: "Current product",
    },
    showProductDetails: {
      type: Boolean,
      description: "show me",
    },
  },
  data() {
    return {
      currencySymb: { eur: "€", dol: "$", $: "$", "€": "€" },
    };
  },
  methods: {
    close() {
      this.$emit("showProductDetails", false);
    },
    saveProduct() {
      this.loading = true;
      this.$store.dispatch("products/save", this.product).then(
        (p) => {
          this.loading = false;
          this.product = p;
          this.close();
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            message: this.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
          localStorage.removeItem("user");
          this.$router.push("/login");
        }
      );
      this.$emit("saveProduct", this.product);
    },
  },
  mounted() {
  },
  computed: {
    showMee: {
      get() {
        return this.showProductDetails;
      },
      set(check) {
        this.$emit("showProductDetails", check);
      },
    },
  },
};
</script>
