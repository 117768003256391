<template>
  <modal
    :show.sync="showMee"
    id="customerModal"
    :centered="false"
    :show-close="true"
    gradient="primary"
    footerClasses="modal-footer"
  >
    <card>
      <h1 slot="header" class="text-uppercase text-center">
        {{ customer.firstname + " " + customer.lastname }}
      </h1>

      <div class="row">
        <div class="col-md-6 pr-md-1">
          <base-input
            label="First Name"
            v-model="customer.firstname"
            :placeholder="$t('customer.firstname')"
          >
          </base-input>
        </div>
        <div class="col-md-6 pl-md-1">
          <base-input
            label="Name"
            v-model="customer.lastname"
            :placeholder="$t('customer.lastname')"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 pr-md-1">
          <base-input
            label="Email"
            v-model="customer.email"
            :placeholder="$t('customer.email')"
          >
          </base-input>
        </div>
        <div class="col-md-6 pl-md-1">
          <base-input
            label="Phone"
            v-model="customer.phone"
            :placeholder="$t('customer.phone')"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 pr-md-1">
          <base-input
            :label="$t('address.addressline1')"
            v-if="customer.address"
            v-model="customer.address.line1"
            :placeholder="$t('address.addressline1')"
          >
          </base-input>
        </div>
        <div class="col-md-3 pr-md-1">
          <base-input
            :label="$t('address.addressline2')"
            v-if="customer.address"
            v-model="customer.address.line2"
            :placeholder="$t('address.addressline2')"
          >
          </base-input>
        </div>
        <div class="col-md-3 pr-md-1">
          <base-input
            :label="$t('address.zipCode')"
            v-if="customer.address"
            v-model="customer.address.zipCode"
            :placeholder="$t('address.zipCode')"
          >
          </base-input>
        </div>
        <div class="col-md-3 pr-md-1">
          <base-input
            :label="$t('address.city')"
            v-if="customer.address"
            v-model="customer.address.city"
            :placeholder="$t('address.city')"
          >
          </base-input>
        </div>
      </div>

      <div class="row" v-if="customer.payments">
        <div class="col-md-3 text-uppercase header-title">
          {{ this.$t("customer.payments") }}
        </div>
        <div class="col-md-12">
          <base-table
            :data="customer.payments"
            :columns="paymentHeader"
            tbody-classes="black-table"
          >
            <columns>
              <th></th>
            </columns>
            <template slot-scope="{ row }">
              <td>
                {{ row.payType }}
              </td>
              <td>
                {{ row.number }}
              </td>
              <td>
                {{ row.expDate }}
              </td>
              <td>
                {{ row.ccv }}
              </td>
            </template>
          </base-table>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <base-button type="primary" block @click="saveCustomer"
            >{{ this.$t('common.save') }}</base-button
          >
        </div>
        <div class="col-md-4">
          <base-button type="secondary" block @click="close"
            >{{ this.$t('common.cancel') }}</base-button
          >
        </div>
      </div>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import NotificationTemplate from "../Notifications/NotificationTemplate";
import Card from "../../components/Cards/Card.vue";
import { BaseTable } from "@/components";

export default {
  name: "customer-detail",
  components: {
    Modal,
    BaseTable,
  },

  props: {
    customer: {
      type: Object,
      description: "Current customer",
    },
    showCustomerDetails: {
      type: Boolean,
      description: "show me",
    },
  },
  data() {
    return {
      currencySymb: { eur: "€", dol: "$", $: "$", "€": "€" },
      paymentHeader: [this.$t('payment.type'), this.$t('payment.number'), this.$t('payment.expDate'), this.$t('common.ccv')],
    };
  },
  methods: {
    close() {
      this.$emit("showCustomerDetails", false);
    },
    saveCustomer() {
      //emit saving event for this specifique order
      this.loading = true;
      this.$store.dispatch("customers/save", this.customer).then(
        (c) => {
          this.loading = false;
          this.customer = c;
          this.close();
          // this.$router.push("/dashboard");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            message: this.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
          localStorage.removeItem("user");
          this.$router.push("/login");
        }
      );
      this.$emit("saveCustomer", this.customer);
    },
  },
  mounted() {
    //load order details if needed
  },
  computed: {
    showMee: {
      get() {
        return this.showCustomerDetails;
      },
      set(check) {
        this.$emit("showCustomerDetails", check);
      },
    },
  },
};
</script>
