<template>
  <card :title="$t('product.title')">
    <template slot="header">
      <h6 class="title d-inline">{{ this.$t("product.title") }}</h6>
      <div class="float-right">
        <base-dropdown
          menu-on-right=""
          tag="div"
          title-classes="btn btn-link btn-icon"
          aria-label="actions menu"
        >
          <i slot="title" class="tim-icons icon-settings-gear-63"></i>
          <button
            class="dropdown-item"
            @click="createProduct"
            style="color: #344675"
          >
            <i slot="title" class="tim-icons icon-simple-add"></i>

            {{ this.$t("product.create") }}
          </button>
          <button
            class="dropdown-item"
            @click="importProducts"
            style="color: #344675"
          >
            <i slot="title" class="tim-icons icon-cloud-upload-94"></i>
            {{ this.$t("product.import") }}
          </button>
          <button
            class="dropdown-item"
            @click="exportProducts"
            style="color: #344675"
          >
            <i slot="title" class="tim-icons icon-cloud-download-93"></i>

            {{ this.$t("product.export") }}
          </button>
          <button
            class="dropdown-item"
            @click="findProducts"
            style="color: #344675"
          >
            <i slot="title" class="tim-icons icon-zoom-split"></i>

            {{ this.$t("product.find") }}
          </button>
        </base-dropdown>
      </div>
    </template>
    <product-detail
      :product="currProd"
      :showProductDetails="showProductDetails"
      @showProductDetails="showProductDetailChanges"
      @saveProduct="saveProduct"
    >
    </product-detail>
    <div class="row">
      <div class="col-md-12 pr-md-1" align="center" style="margin-bottom: 5px">
        <loading :isLoading="loading"></loading>
      </div>
    </div>
    <b-table
      striped
      hover
      :items="products"
      :fields="bheader"
      id="tableProduct"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(action)="row">
        <base-button
          type="link"
          aria-label="edit button"
          style="color: #00f2c3"
          @click="productDetail(row.item)"
        >
          <i class="tim-icons icon-pencil"></i>
        </base-button>
      </template>
      <template #cell(name)="row">
        <p class="title">
          {{ row.item.name }}
        </p>
        <p class="text-muted">{{ row.item.description }}</p>
      </template>
      <template #cell(createdAt)="row">
        <p class="title">
          {{ row.item.createdAt | formatDate }}
        </p>
      </template>
       <template #cell(id)="row">
        <p class="title">
          {{ row.item.id }}
        </p>
      </template>
      <template #cell(externalId)="row">
        <p class="title">
          {{ row.item.externalId }}
        </p>
      </template>
      <template #cell(price)="row">
        <p class="title">
          {{ row.item.price ? (row.item.price / 100) + '€' : 0 }}
        </p>
        <p class="text-muted">{{ row.item.currency }}</p>
      </template>
      <template #cell(taxRate)="row">
        <p class="title">
          {{ row.item.taxRate ? (row.item.taxRate ) + '%' : '-' }}
        </p>
      </template>
    </b-table>
    <div class="row">
      <div class="col-md-12 pr-md-1">
        <b-pagination
          v-model="currentPage"
          size="sm"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="tableProduct"
          align="center"
          :current-page="currentPage"
        ></b-pagination>
      </div>
    </div>
  </card>
</template>

<script>
import { BaseTable } from "@/components";
import Modal from "@/components/Modal";
import ProductDetail from "./productDetail";
import { BTable } from "bootstrap-vue";
import { now } from "moment";

import loading from "@/components/loading";

export default {
  components: {
    BaseTable,
    Modal,
    ProductDetail,
    BTable,
    loading,
  },
  data() {
    return {
      products: [],
      currentPage: 1,
      bheader: [
        {
          key: "id",
          label: this.$t("common.id"),
          sortable: true,
          class: "title",
        },
        {
          key: "externalId",
          label: this.$t("common.externalId"),
          sortable: true,
          class: "title",
        },
        {
          key: "name",
          label: this.$t("product.name"),
          sortable: true,
          _rowVariant: "title",
        },
        {
          key: "price",
          label: this.$t("product.price"),
          sortable: true,
        },
        {
          key: "taxRate",
          label: this.$t("product.taxRate"),
          sortable: true,
        },
        {
          key: "action",
          label: this.$t("common.action"),
          sortable: false,
        },
      ],
      showProductDetails: false,
      currProd: {},
      perPage:
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).options
          ? JSON.parse(localStorage.getItem("user")).options.numberLine
          : "6",
      currentPage: 1,
      loading: true,
    };
  },
  methods: {
    getProducts() {
      //here get commande for the connected user.
      this.products = [];
    },
    productDetail(product) {
      this.currProd = product;
      this.showProductDetails = true;
    },

    showProductDetailChanges(check) {
      console.log("changing product details");
      this.showProductDetails = check;
    },
    saveProduct(o) {
      this.$notify({
        message: this.$t("product.saved"),
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "success",
      });
    },
    createProduct() {
      //create a new product : open the modal with no ID
      console.log("create a new product");
    },
    importProducts() {
      //create a new product : open the modal with no ID
      console.log("importing a new product");
    },
    findProducts() {
      //create a new product : open the modal with no ID
      console.log("finding a new product");
    },
    exportProducts() {
      let arrData = this.products;
      this.csvExport(arrData);
    },
    csvExport(arrData) {
      this.loading = true;
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", `exportProducts-${Date.now()}.csv`);
      link.click();
      this.loading = false;
    },
  },
  created() {
    this.loading = true;
    this.$store.dispatch("products/list", this.products).then(
      (c) => {
        this.loading = false;
        this.products = c;
      },
      (error) => {
        this.loading = false;
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.$notify({
          message: this.message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "danger",
        });
        localStorage.removeItem("user");
        this.$router.push("/login");
      }
    );
  },
  computed: {
    rows() {
      return this.products.length;
    },
  },
};
</script>

<style >
</style>