<template>
  <modal
    :show.sync="showMee"
    id="InvoiceModal"
    :centered="false"
    :show-close="true"
    gradient="primary"
    footerClasses="modal-footer"
  >
    <card>
      <h1 slot="header" class="text-uppercase text-center">
         <p class="title text-white">{{ $t('invoice.label') + ' ' +invoice.invoiceNumber}}</p> 
      </h1>
      

      <div class="row mb-3">
      <div class="col-md-6 pl-md-1 text-uppercase text-white">
        <span class="text-muted">
            {{ $t("invoice.invoiceNumber") }} :
          </span>
           {{invoice.invoiceNumber }}
        </div>
        <div class="col-md-6 pl-md-1 text-uppercase text-white">
          <span class="text-muted">
            {{ $t("invoice.totalAmount")  }} : 
          </span>
           {{  (invoice.transaction ? invoice.transaction.payment.amount_received /100 + invoice.transaction.payment.currency || '€': 'Error!!')}}
        </div>
        <div class="col-md-6 pl-md-1 text-uppercase text-white">
           <span class="text-muted">
            {{ $t("invoice.customer")  }} : 
          </span> {{ invoice.transaction.customerId}}
        </div>
        <div class="col-md-6 pl-md-1 text-uppercase text-white">
           <span class="text-muted">
            {{ $t("invoice.address")  }} : 
          </span> 
          {{ invoice.address.line1}}
          {{ invoice.address.zipCode}}
          {{ invoice.address.city}}
          {{ invoice.address.country}}
        </div>
        <div class="col-md-6 pl-md-1 text-uppercase text-white">
           <span class="text-muted">
            {{ $t("common.date")  }} : </span> 
            <span>
            {{ invoice.createdAt | formatDate}}
          </span> 
        </div>
         
      </div>
      <div class="row mb-3">
              <products-customer-order
                :products="invoice.transaction.products.filter(p => p.qty >0)" 
                class="mb-0"
                :readOnly="true"
                
              >
              </products-customer-order>
            </div>
        
        <div class="row col-md-12">
          <div class="col-md-6 px-4">
            <base-button type="secondary" block @click="close"
                        >{{$t("common.close")}}</base-button
                      >
          </div>
          
          <div class="col-md-6 px-4">
            <base-button type="danger" block @click="getPDF" v-if="!loading"
            >{{$t("invoice.generate")}} </base-button
          >
          <loading :isLoading="loading"></loading>
          </div>
          
        </div>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import { BaseTable } from "@/components";
import ProductsCustomerOrder from "../order/ProductsCustomerOrder.vue";
import loading from "@/components/loading";

export default {
  name: "invoice-detail",
  components: {
    Modal,
    BaseTable,
    ProductsCustomerOrder,
    loading,
  },

  props: {
    invoice: {
      type: Object,
      description: "Current Invoice",
    },
    showInvoiceDetails: {
      type: Boolean,
      description: "show me",
    },
  },
  data() {
    return {
      currencySymb: { eur: "€", dol: "$", $: "$", "€": "€" },
      loading :  false,
    };
  },
  methods: {
    close() {
      this.$emit("showInvoiceDetails", false);
    },
    saveInvoice() {
      //emit saving event for this specifique order
      this.loading = true;
      this.$store.dispatch("invoices/save", this.invoice).then(
        (p) => {
          this.loading = false;
          this.invoice = p;
          this.close();
          // this.$router.push("/dashboard");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            message: this.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
          localStorage.removeItem("user");
          this.$router.push("/login");
        }
      );
      this.$emit("saveinvoice", this.order);
    },
    getInvoice(invoiceId) {
      //get invoice Object 
      //console.log("getting invoice of", invoiceId)
      //print 
      return "/invoices/" + invoiceId + "/"
    },
    getPDF(){
      console.log(this.invoice._id)
      this.loading = true;
      this.$store.dispatch("invoices/getPdfInvoiceById", this.invoice).then(
        (p) => {
          
            const linkSource = `data:application/pdf;base64,${p.data}`;
            const downloadLink = document.createElement("a");
            const fileName = `invoice-${this.invoice._id}.pdf`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          this.loading = false;
          // console.log("pdf", p)
          // this.loading = false;
          // var windo = window.open("", "");  
          // var objbuilder = '';
          // objbuilder +='<head><title>Paydone | Invoice N° : '+ this.invoice._id +'</title><link rel="icon" type="image/png" sizes="32x32" href="/img/icons/favicon-32x32.png"></head>'
          // objbuilder += ('<embed width=\'100%\' height=\'100%\'  src="data:application/pdf;base64,');
          // objbuilder += (p.data);
          // objbuilder += ('" type="application/pdf" />');
          // windo.document.write(objbuilder); 
          
        },
        (error) => {
          this.loading = false;
          console.log("Error", error)
        }
      );

    }
  },
  mounted() {
    //load order details if needed
  },
  computed: {
    showMee: {
      get() {
        return this.showInvoiceDetails;
      },
      set(check) {
        this.$emit("showInvoiceDetails", check);
      },
    },
  },
};
</script>
