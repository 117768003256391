<template>
  <card>
    <h5 slot="header" class="title">{{ $t('user.title') }}</h5>
    <div class="row">
      <div class="col-md-6">
        <base-input
          :label="$t('user.partnerId')"
          :placeholder="$t('user.partnerId')"
          v-model="model.partnerId"
          disabled
        >
        </base-input>
      </div>
      <!-- <div class="col-md-6">
        <base-input
          label="Raison Sociale"
          placeholder="Raison Sociale"
          v-model="model.companyName"
        >
        </base-input>
      </div> -->
      </div>
      <div class="row">
        <div class="col-md-6">
        <base-input
          :label="$t('user.username')"
          :placeholder="$t('user.username')"
          v-model="model.username"
        >
        </base-input>
      </div>
      <div class="col-md-6">
        <base-input
          :label="$t('user.email')"
          type="email"
          :placeholder="$t('user.emailplaceholder')"
          v-model="model.email"
        >
        </base-input>
      </div>
      </div>
    
    <div class="row">
      <div class="col-md-6">
        <base-input
          :label="$t('user.firstname')"
          v-model="model.firstname"
          :placeholder="$t('user.firstname')"
        >
        </base-input>
      </div>
      <div class="col-md-6">
        <base-input
          :label="$t('user.lastname')"
          v-model="model.lastname"
          :placeholder="$t('user.lastname')"
        >
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <base-input
          :label="$t('address.label')"
          v-model="model.address.line1"
          :placeholder="$t('address.label')"
        >
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 pr-md-1">
        <base-input label="City" v-model="model.address.city" placeholder="City">
        </base-input>
      </div>
      <div class="col-md-4 px-md-1">
        <base-input
          :label="$t('address.country')"
          v-model="model.address.country"
          :placeholder="$t('address.country')"
        >
        </base-input>
      </div>
      <div class="col-md-4 pl-md-1">
        <base-input 
          :label="$t('address.zipCode')" 
          :placeholder="$t('address.zipCode')"  
          v-model="model.address.zipCode"> 
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <label for="pspKey">{{$t('user.pspKey')}}</label>
        <base-button 
          v-if="isPkpKeyHidden"
            type="link"
            >
            <b-icon
            @click="togglePspKey"
            id='pspkey-icon'
            icon="eye" 
            style="color: #00f2c3"
            >
        </b-icon>
          </base-button>
          <base-button 
            v-else
            type="link"
            >
            <b-icon
            @click="togglePspKey"
            id='pspkey-icon'
            icon="eye-slash"
            style="color: #00f2c3"
            >
        </b-icon>
          </base-button>
      </div>
      <div class="col-md-10 pr-md-1">
         <textarea 
        disabled
          name="pspKey"
          :label="$t('user.pspKey')" 
          rows="2"
          cols="80"
          v-model="hiddenKey" 
          class="form-control"
          style="color: #00f2c3"
          >
        </textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <label for="publicKey">{{$t('user.publicKey')}}</label>
        <base-button 
          v-if="isPublicKeyHidden"
            type="link"
            >
            <b-icon
            @click="togglePublicKey"
            icon="eye" 
            style="color: #00f2c3"
            >
        </b-icon>
          </base-button>
          <base-button 
            v-else
            type="link"
            >
            <b-icon
            @click="togglePublicKey"
            icon="eye-slash"
            style="color: #00f2c3"
            >
        </b-icon>
          </base-button>
      </div>
      <div class="col-md-10 pr-md-1">
         <textarea 
        disabled
          name="publicpKey"
          :label="$t('user.publicKey')" 
          rows="2"
          cols="80"
          v-model="hiddenPublicKey" 
          class="form-control"
          style="color: #00f2c3"
          >
        </textarea>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-2">
        <label for="pspKey">Private Key</label>
        <base-button 
          v-if="isPrivateKeyHidden"
            type="link"
            >
            <b-icon
            @click="togglePrivateKey"
            icon="eye" 
            style="color: #00f2c3"
            >
        </b-icon>
          </base-button>
          <base-button 
            v-else
            type="link"
            >
            <b-icon
            @click="togglePrivateKey"
            icon="eye-slash"
            style="color: #00f2c3"
            >
        </b-icon>
          </base-button>
      </div>
      <div class="col-md-10 pr-md-1">
         <textarea 
        disabled
          label="psp Key" 
          rows="2"
          cols="80"
          v-model="hiddenPrivateKey" 
          class="form-control"
          placeholder="Private Key"
          style="color: #00f2c3"
          >
        </textarea>
      </div>
    </div> -->
     <div class="row">
      <div class="col-md-6">
        <label>{{$t('user.language')}}
        <b-dropdown split
          split-variant="outline-primary"
          variant="primary"
          :text="this.availableLanguages[this.model.language].label"
          class="m-2"
          size="sm">
            <b-dropdown-item-btn id="en" @click="languageSelected"> {{$t('languages.english')}}</b-dropdown-item-btn>
            <b-dropdown-item-button id="fr"  @click="languageSelected" >{{$t('languages.french')}}</b-dropdown-item-button>
          </b-dropdown>
          </label>
      </div>
       <div class="col-md-6">
         <label>{{$t('user.numberlines')}}
        <b-dropdown split
          split-variant="outline-primary"
          variant="primary"
          :text="this.model.options.numberLine"
            class="m-2"
            size="sm">
            <b-dropdown-item-button id="5" @click="updateNumberLine" >5</b-dropdown-item-button>
            <b-dropdown-item-button id="10" @click="updateNumberLine" >10</b-dropdown-item-button>
            <b-dropdown-item-button id="20" @click="updateNumberLine" >20</b-dropdown-item-button>
            <b-dropdown-item-button id="50" @click="updateNumberLine" >50</b-dropdown-item-button>
            <b-dropdown-item-button id="100" @click="updateNumberLine" >100</b-dropdown-item-button>
          </b-dropdown>
          </label> 
       </div>
      </div>
    <base-button slot="footer" type="primary" fill @click="save">{{$t('common.save')}}</base-button>
  </card>
</template>
<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => {
        return {
          address : {
           line1: "",
            line2: "",
            line3: "",
            zipCode: "",
            city: "",
            country: "",
            countryCode: "",
            State: ""
          }
          };
      },
    },
  },
  data () {
    return {
      user : {},
      isPkpKeyHidden : true,
      isPublicKeyHidden : true,
      isPrivateKeyHidden : true,
      publicKey : '',
      privateKey : '',
      connectedUser : JSON.parse(localStorage.getItem("user")),
      availableLanguages : {
        'fr': {label : this.$t('languages.french'), code : 'fr-FR'},
        'en': {label : this.$t('languages.english'), code : 'en-US'}
      },
      numberLine : this.model.options.numberLine || "5",
    }
  },
  methods: {
    save(){
      this.loading = true;
      this.$store.dispatch("users/save", this.model).then(
        (u) => {
          this.loading = false;
          this.user = u;
          this.$notify({
            message: this.$t('user.saved'),
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            message: this.message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
          });
          //localStorage.removeItem("user");
          //this.$router.push("/login");
        }
      );
      this.$emit("saveUser", this.user);
    },
    togglePspKey(){
      this.isPkpKeyHidden = !this.isPkpKeyHidden
    },
    togglePublicKey(){
      this.isPublicKeyHidden = !this.isPublicKeyHidden
    },
    togglePrivateKey(){
      this.isPrivateKeyHidden = !this.isPrivateKeyHidden
    },
    languageSelected(e){
      this.model.language = e.srcElement.id;
      this.$root.$i18n.locale = this.model.language
      const user = JSON.parse(localStorage.getItem('user'))
      user.language = this.model.language;
      localStorage.setItem('user', JSON.stringify(user))
    },
    updateNumberLine(e){
      if (!this.model.options) this.model.options = {}
      this.model.options.numberLine = e.srcElement.id;
      this.numberLine = e.srcElement.id;
      const user = JSON.parse(localStorage.getItem('user'))
      user.options.numberLine = this.model.options.numberLine;
      localStorage.setItem('user', JSON.stringify(user))
    }
  },
  computed : {
    hiddenKey (){
      if (this.isPkpKeyHidden) {
        return (this.model ? new Array(this.model.pspKey.length + 1).join( '*') : '*********' )
      }else{
        return this.model.pspKey
      }
      
    },
     hiddenPublicKey (){
      if (this.isPublicKeyHidden) {
        return  "******************"
      }else{
        // get the public key 
        return this.model.publicKey
      }
      
    },
    hiddenPrivateKey (){
      if (this.isPrivateKeyHidden) {
        return '*************************'
      }else{
        return this.model.privateKey
      }
      
    }
  }
};
</script>
<style>
</style>
